<template>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card card-one">
                <div class="card-header">
                    <h3 class="text-primary">Alerts</h3>
                </div>
                <div class="card-body">
                    <div class="table-responsive" ref="rowdy_scroll" style="overflow-y:scroll; height: 65vh;">
                        <table class="table table-hover table-sm table-bordered table-striped mb-0">
                            <thead class="freeze-head text-center">
                                <tr style="background-color: #f5f5f5;">
                                    <th class="text-center">Sl No.</th>
                                    <th class="text-center">Photo</th>
                                    <th class="text-center">Captured<br>Image</th>
                                    <th @click="sort('rowdy_sheeter_name')">Rowdy Name</th>
                                    <th>Alias</th>
                                    <th>Location</th>
                                    <th>Latitude</th>
                                    <th>Longitude</th>
                                    <th>Alert Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="rowdy_location, key in rowdy_locations" :key="key">
                                    <tr>
                                        <td class="text-center">{{ meta.from + key }}</td>
                                        <td>
                                            <a href="javascript:void(0)" data-bs-toggle="modal"
                                                data-bs-target="#enlargeImageModal"
                                                @click="getRowdyImages(rowdy_location.rowdy_id)">
                                                <img :src="rowdy_location.rowdy?.rowdy_sheeter_photo" alt=""
                                                    width="60px">
                                            </a>
                                        </td>
                                        <td>
                                            <div v-if="is_loading" class="loader"></div>
                                            <img :src="rowdy_location.image_url" alt="" width="80px"
                                                @load="handleImageLoaded" @error="handleImageError(key)"
                                                :ref="`image_${key}`">
                                        </td>
                                        <td>
                                            <router-link :to="'/view_rowdy/' + rowdy_location.rowdy_id"
                                                class="text-primary me-2">
                                                {{ rowdy_location.rowdy?.rowdy_sheeter_name }}
                                            </router-link>
                                        </td>
                                        <td>{{ rowdy_location.rowdy?.alias }}</td>
                                        <td>{{ rowdy_location.location }}</td>
                                        <td>{{ rowdy_location.latitude }}</td>
                                        <td>{{ rowdy_location.longitude }}</td>
                                        <td>{{ rowdy_location.created_at }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-between">
                    <div>
                        <select class="form-select border-0 border-bottom" v-model="meta.per_page"
                            @change="setPerPage()">
                            <option value="">Select Per Page</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div>
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                            @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Enlarge image -->
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="enlargeImageModal">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Rowdy Images</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-around">
                            <label class="form-label text-primary">Uploaded By: {{ shift.created_by?.name }}</label>
                        </div>
                        <div class="col-2" style="display: flex; align-items: center;">
                            <button v-if="this.shift.index > 0" class="btn btn-sm btn-outline-primary float-start"
                                @click="shiftLeft()"> <i class="ri-arrow-left-line"></i> </button>
                        </div>
                        <div class="col-8">
                            <img class="modal-img" :src="shift.image" alt="" />
                            <div class="text-overlay">{{ moment(shift.photo_taken_date) }}</div>
                        </div>
                        <div class="col-2" style="display: flex; align-items: center;">
                            <button v-if="shift.index <= rowdy_images.length - 2"
                                class="btn btn-sm btn-outline-primary float-end" @click="shiftRight()"> <i
                                    class="ri-arrow-right-line"></i> </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import Highlighter from 'vue-highlight-words';
import MultiSelect from 'primevue/multiselect';
import moment from "moment";
export default {
    components: {
        Pagination, Highlighter, MultiSelect
    },
    name: "RowdiesCreate",
    data() {
        return {
            meta: {
                search: '',
                order_by: "desc",
                keyword: "created_at",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
            },
            rowdy_locations: [],
            rowdy_images: [],
            shift: {
                index: 0,
                image: '',
                photo_taken_date: ''
            },
            //Image load
            is_loading: true
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.paginateRowdyAlerts();
        });
    },

    methods: {
        paginateRowdyAlerts() {
            let vm = this;
            let uri = { uri: "paginateRowdyAlerts", data: vm.meta };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    vm.rowdy_locations = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getRowdyImages(rowdy_id) {
            this.shift = {}
            let vm = this;
            let loader = vm.$loading.show();
            this.$store.dispatch('post', { uri: 'getRowdyImages', data: { rowdy_id: rowdy_id } })
                .then(response => {
                    loader.hide();
                    this.rowdy_images = response.data.data;
                    if (this.rowdy_images.length) {
                        this.shift.index = 0;
                        this.shift.image = this.rowdy_images[0].image;
                        this.shift.photo_taken_date = this.rowdy_images[0].photo_taken_date;
                        this.shift.created_by = this.rowdy_images[0].created_by;
                    }
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        shiftLeft() {
            if (this.rowdy_images.length) {
                this.shift.index--;
                this.shift.image = this.rowdy_images[this.shift.index].image;
                this.shift.photo_taken_date = this.rowdy_images[this.shift.index].photo_taken_date;
                this.shift.created_by = this.rowdy_images[this.shift.index].created_by;
            }
        },

        shiftRight() {
            if (this.rowdy_images.length) {
                this.shift.index++;
                this.shift.image = this.rowdy_images[this.shift.index].image;
                this.shift.photo_taken_date = this.rowdy_images[this.shift.index].photo_taken_date;
                this.shift.created_by = this.rowdy_images[this.shift.index].created_by;
            }
        },

        setPerPage() {
            this.meta.page = 1;
            this.paginateRowdyAlerts();
        },

        moment(date) {
            if (date) {
                return moment(date).format('DD-MM-YYYY');
            }
        },
        onPageChange(page) {
            this.meta.page = page;
            this.paginateRowdyAlerts();
        },

        //Image error
        handleImageError(index) {
            const imageRef = this.$refs[`image_${index}`];
            if (imageRef) {
                imageRef[0].src = 'assets/img/image_load_error.jpg';
            }
            this.is_loading = false;
        },

        handleImageLoaded() {
            this.is_loading = false;
        }
    }
}
</script>
<style scoped>
.card-one {
    border-width: 0px;
}

@media only screen and (max-width: 767px) {
    .gap-2 {
        margin-bottom: 0.5rem;
    }
}

.dropdown {
    position: relative;
    cursor: pointer;
}

.multiselect {
    position: relative;
}

.multiselect ul {
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    top: -0.1rem;
    width: 100%;
    list-style: none;
    max-height: 150px;
    overflow: auto;
    background: white;
}

.overselect {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.multiselect {
    position: relative;
}

.multiselect ul {
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    top: -0.1rem;
    width: 100%;
    list-style: none;
    max-height: 150px;
    overflow: auto;
    background: white;
}

thead {
    /* position: relative !important; */
    top: -2px !important;
    z-index: 5 !important;
    background-color: #f3f5f9
}

.modal-body .col-8 {
    position: relative;
}

.modal-body .modal-img {
    width: 100%;
    height: auto;
}

.text-overlay {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 5px;
}

.loader {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
