<template>
    <div v-if="update_status" class="gap-4 mt-2 mb-4">
        <a class="text-dark" href="javascript:void(0)" @click="goBack()"><i class="ri-arrow-left-line fs-24"></i></a>
        <h2 class="main-title fs-28 mb-0 ms-1" style="display: inline;">Update Gang - {{ gang.gang_name }}</h2>
    </div>
    <div v-else class="d-md-flex align-items-center justify-content-between gap-4 mt-2 mb-4">
        <h2 class="main-title fs-28 mb-0">New Gang</h2>
        <router-link v-can="'gang.gang_list'" to="/gang_list"
            class="mt-auto btn btn-outline-primary d-flex align-items-center gap-2">
            <i class="ri-menu-line fs-18 lh-1"></i>All Gangs</router-link>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <ul class="nav nav-tabs card-header-tabs mb-0" data-bs-tabs="tabs"
                        style="margin-top: -17px; margin-left: -17px;">
                        <li v-can="'gang.update'" class="nav-item">
                            <a v-if="update_status" class="nav-link text-dark fw-semibold fs-16 active"
                                aria-current="true" data-bs-toggle="tab" href="#gangDetails">Update Gang</a>
                            <a v-else class="nav-link text-dark fw-semibold fs-16 active" aria-current="true"
                                data-bs-toggle="tab" href="#gangDetails">Add Gang</a>
                        </li>
                        <li v-can="'gang.assign_follower'" class="nav-item"
                            :class="{ 'gang_disabled': !update_status }">
                            <a class="nav-link text-dark fw-semibold fs-16" :disabled="!update_status"
                                data-bs-toggle="tab" href="#assignFollower">Assign Followers</a>
                        </li>
                        <li v-can="'gang.assign_opposite_gang'" class="nav-item"
                            :class="{ 'gang_disabled': !update_status }">
                            <a class="nav-link text-dark fw-semibold fs-16" :disabled="!update_status"
                                data-bs-toggle="tab" href="#oppositeGang">Assign Opposite Gang</a>
                        </li>
                    </ul>
                    <div class="tab-content mt-4">
                        <div class="tab-pane active" id="gangDetails">
                            <!-- <h5 class="main-title fw-semibold mb-0">Rowdy Sheeter Input Details</h5> -->
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label">Gang Name</label> <span class="text-danger">*</span>
                                    &nbsp;<a v-if="update_status" href="javascript::void(0)" data-bs-toggle="modal"
                                        data-bs-target="#infoModal" @click="fieldHistory('gang_name')"><i
                                            class="ri-information-2-line"></i></a>
                                    <input type="text" class="form-control border-0 border-bottom"
                                        v-model="gang.gang_name" :class="{ 'is-invalid': errors.gang_name }"
                                        placeholder="Enter gang name ..." />
                                    <span v-if="errors.gang_name" class="invalid-feedback">{{
                                        errors.gang_name[0] }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div v-can="'gang.update'" class="col-12">
                                    <button v-if="update_status" class="btn btn-primary float-end"
                                        @click="updateGang()">Update</button>
                                    <button v-else class="btn btn-primary float-end" @click="addGang()">Submit</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="assignFollower">
                            <div class="row">
                                <div class="col-6 mb-3">
                                    <label class="form-label">Select Follower <span class="text-danger">*</span>
                                    </label>
                                    <search :class="{ 'is-invalid': errors.rowdy_id }"
                                        :customClass="{ 'is-invalid': errors?.rowdy_id }" :initialize="rowdy_id"
                                        id="rowdy_id" label="rowdy_sheeter_name" placeholder="Select Rowdy..."
                                        label2="father_name" label3="police_stations" :data="rowdies"
                                        @selected="rowdy => rowdy_id = rowdy">
                                    </search>
                                    <span v-if="errors.rowdy_id" class="invalid-feedback">{{ errors.rowdy_id[0]
                                    }}</span>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Follower Type <span class="text-danger">*</span>
                                    </label>
                                    <select class="form-control border-0 border-bottom form-select"
                                        v-model="follower.follower_type"
                                        :class="{ 'is-invalid': errors.follower_type }">
                                        <option value="">Select</option>
                                        <option value="Leader">Leader</option>
                                        <option value="Associate">Associate</option>
                                    </select>
                                    <span v-if="errors.follower_type" class="invalid-feedback">{{
                                        errors.follower_type[0] }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button class="btn btn-primary float-end" @click="assignFollower()">Assign</button>
                                </div>
                            </div>
                            <div class="table-responsive mt-2" style="overflow-y:scroll; height: 50vh;">
                                <table class="table table-sm bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Follower Name</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Assigned by PS</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="follower, key in followers" :key="key">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ follower.rowdy.rowdy_sheeter_name }}</td>
                                            <td>{{ follower.follower_type }}</td>
                                            <td>
                                                <select name="" id="" v-model="follower.status"
                                                    class="form-control form-control-sm form-select"
                                                    @change="updateFollowerStatus(follower)">
                                                    <option value="Active">Active</option>
                                                    <option value="Left">Left</option>
                                                    <option value="Dead">Dead</option>
                                                    <option value="Closed">Closed</option>
                                                </select>
                                            </td>
                                            <td>
                                                {{ follower.created_by?.name }}
                                            </td>
                                            <td class="text-center">
                                                <button class="btn btn-sm btn-outline-danger"
                                                    @click="deleteFollower(follower.follower_id)"><i
                                                        class="ri-delete-bin-line" title="Delete follower"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane" id="oppositeGang">
                            <div class="row">
                                <div class="col-12">
                                    <label class="form-label">Select Opposite Gang</label>
                                    <search :class="{ 'is-invalid': errors.opponent_gang_id }"
                                        :customClass="{ 'is-invalid': errors?.opponent_gang_id }"
                                        :initialize="opponent_gang_id" id="gang_id" label="gang_name"
                                        placeholder="Select Gang..." :data="gangs"
                                        @selected="gang => opponent_gang_id = gang">
                                    </search>
                                    <span v-if="errors.opponent_gang_id" class="invalid-feedback">{{
                                        errors.opponent_gang_id[0] }}</span>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <button class="btn btn-primary float-end" @click="addOppositeGang()">Assign</button>
                                </div>
                            </div>
                            <div class="table-responsive mt-2">
                                <table class="table table-sm bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Gang Name</th>
                                            <th>Leader</th>
                                            <th>Assigned By PS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="gang, key in opposite_gangs" :key="key">
                                            <td>{{ key + 1 }}</td>
                                            <td>{{ gang.opposite_gang.gang_name }}</td>
                                            <td>{{ gang.opposite_gang.leader?.rowdy?.rowdy_sheeter_name }}</td>
                                            <td>
                                                {{ gang.created_by?.name }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- History information modal -->
    <div class="modal fade bd-example-modal-md" tabindex="-1" id="infoModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edited History</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive small" style="overflow-y: scroll; height: 50vh;">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr class="text-center">
                                    <th>#</th>
                                    <th>NEW {{ field_name.replace(/_/g, ' ') }}</th>
                                    <th>OLD {{ field_name.replace(/_/g, ' ') }}</th>
                                    <th>Edited By</th>
                                    <th>Date Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="field_history.length == 0">
                                    <td colspan="5" class="text-center">No Records to display!</td>
                                </tr>
                                <tr v-for="(edited, key) in field_history" :key="key" v-else>
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td>{{ edited.new_values[field_name] }}</td>
                                    <td>{{ edited.old_values[field_name] }}</td>
                                    <td>{{ edited.user.name }}</td>
                                    <td>{{ edited.created_at }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let Search = require("@/components/Search.vue").default;
export default {
    components: { Search },
    data() {
        return {
            gang: {
                gang_id: '',
                gang_name: ''
            },
            gangs: [],
            opposite_gang: {
                gang_id: '',
                opponent_gang_id: ''
            },
            opponent_gang_id: '',
            errors: [],
            update_status: false,
            create_gang: false,
            rowdies: [],
            follower: {
                follower_id: '',
                gang_id: '',
                rowdy_id: '',
                follower_type: '',
                status: ''
            },
            rowdy_id: '',
            followers: [],
            opposite_gangs: [],
            path: '',
            gang_edit_histories: [],
            field_history: [],
            field_name: '',
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.path = from.path;
            if (to.name == 'UpdateGang') {
                vm.update_status = true;
                vm.gang.gang_id = to.params.gang_id;
                vm.getGang();
                vm.allRowdies();
                vm.getGangs();
                vm.getFollowers();
            }

            // if (to.name == 'CreateGang' && from.name == 'UpdateGang') {
            //     vm.update_status = false;
            //     vm.$router.go();
            // }
        });
    },

    methods: {
        getGang() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getGang', data: this.gang })
                .then(response => {
                    loader.hide();
                    this.gang = response.data.data;
                    this.updateGangs();
                    this.getGangEditHistory(this.gang.gang_id)
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        addGang() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'addGang', data: this.gang })
                .then(response => {
                    loader.hide();
                    if (sessionStorage.getItem('gangs')) {
                        this.gangs = JSON.parse(sessionStorage.getItem('gangs'));
                        this.gangs.push(response.data.gang);
                        sessionStorage.setItem('gangs', JSON.stringify(this.gangs));
                    }
                    this.$router.push('/update_gang/' + response.data.gang_id);
                    this.$store.dispatch('success', response.data.message);
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                });
        },

        updateGang() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateGang', data: this.gang })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.gang = response.data.gang;
                    this.errors = [];
                    this.getGangEditHistory(this.gang.gang_id)
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                });
        },

        allRowdies() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'allRowdies' })
                .then(response => {
                    loader.hide();
                    this.rowdies = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        assignFollower() {
            this.follower.gang_id = this.gang.gang_id;
            this.follower.rowdy_id = this.rowdy_id;
            this.follower.status = 'Active';
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'assignFollower', data: this.follower })
                .then(response => {
                    loader.hide();
                    this.errors = [];
                    this.$store.dispatch('success', response.data.message);
                    this.getFollowers();
                    this.getGang()
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getGangs() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getGangs' })
                .then(response => {
                    loader.hide();
                    this.gangs = response.data.data;
                    let temp = this.gangs.filter(ele => {
                        return this.gang.gang_id != ele.gang_id;
                    })
                    this.gangs = temp;
                    this.getOppositeGangs();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        addOppositeGang() {
            this.opposite_gang.gang_id = this.gang.gang_id;
            this.opposite_gang.opponent_gang_id = this.opponent_gang_id;
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'addOppositeGang', data: this.opposite_gang })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.errors = [];
                    this.getOppositeGangs();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getFollowers() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getFollowers', data: { gang_id: this.gang.gang_id } })
                .then(response => {
                    loader.hide();
                    this.followers = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getOppositeGangs() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getOppositeGangs', data: { gang_id: this.gang.gang_id } })
                .then(response => {
                    loader.hide();
                    this.opposite_gangs = response.data.data;
                    let temp = this.gangs.filter(({ gang_id: id1 }) => !this.opposite_gangs.some(({ gang_id: id2 }) => id2 === id1));
                    this.gangs = temp;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        updateFollowerStatus(follower) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateFollowerStatus', data: follower })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', 'Status updated successfully');
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        deleteFollower(follower_id) {
            if (!confirm("Follower will be deleted!")) {
                return;
            }
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'deleteFollower', data: { follower_id: follower_id } })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    this.getFollowers();
                    this.getGang();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        updateGangs() {
            if (sessionStorage.getItem('gangs')) {
                let gangs = JSON.parse(sessionStorage.getItem('gangs'));
                let new_list = gangs.map(ele => {
                    return ele.gang_id !== this.gang.gang_id ? ele : this.gang;
                });
                sessionStorage.removeItem('gangs');
                sessionStorage.setItem('gangs', JSON.stringify(new_list));
            }
        },

        goBack() {
            this.$router.go(-1)
        },

        getGangEditHistory(gang_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getGangEditHistory', data: { gang_id: gang_id } })
                .then(response => {
                    loader.hide();
                    this.gang_edit_histories = response.data.data;
                })
                .catch(error => {
                    loader.hide()
                    vm.errors = error.response.data.errors
                })
        },

        fieldHistory(field_name) {
            this.field_name = field_name;
            this.field_history = this.gang_edit_histories.filter(ele => {
                return Object.hasOwn(ele.old_values, field_name) || Object.hasOwn(ele.new_values, field_name)
            })
        },
    }
}
</script>

<style>
.gang_disabled {
    color: darkgray;
    background-color: lightgray;
}
</style>
