<template>
    <div class="d-md-flex align-items-center justify-content-between gap-4 mt-2 mb-4">
        <div>
            <a class="text-dark" href="javascript:void(0)" @click="goBack()"><i
                class="ri-arrow-left-line fs-24"></i></a>
            <h3 v-if="update_status" style="display: inline;">Update Case</h3>
            <h3 v-else style="display: inline;">Add Case</h3>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="card-body">
                        <h5 class="main-title fw-semibold mb-0">Rowdy Name: {{ rowdy.rowdy_sheeter_name }}</h5>
                        <hr>
                        <div class="tab-pane active" id="gangDetails">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row g-3">
                                        <div class="col-4 mb-3">
                                            <label class="form-label">FIR Date</label>
                                            <!-- <span class="text-danger">*</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'fir_date')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="date"
                                                class="form-control form-control-sm border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.fir_date }"
                                                v-model="rowdy_case.fir_date" />
                                            <span v-if="errors.fir_date" class="invalid-feedback">{{ errors.fir_date[0]
                                                }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Police Station</label>
                                            <span class="text-danger"> *</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'police_station')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <search :class="{ 'is-invalid': errors?.police_station_id }"
                                                :customClass="{ 'is-invalid': errors?.police_station_id }"
                                                :initialize="police_station_id" id="police_station_id"
                                                label="police_station" placeholder="Select police station"
                                                :data="police_stations"
                                                @selected="police_station => police_station_id = police_station">
                                            </search>
                                            <span v-if="errors.police_station_id" class="invalid-feedback">{{
                                                errors.police_station_id[0] }}</span>
                                        </div>
                                        <div v-if="isOthers" class="col-4 mb-3">
                                            <label class="form-label">Other Police Station</label>
                                            <span class="text-danger"> *</span>
                                            <input class="form-control border-0 border-bottom"
                                                v-model="rowdy_case.other_police_station" type="text"
                                                :customClass="{ 'is-invalid': errors?.other_police_station }">
                                            <span v-if="errors.other_police_station" class="invalid-feedback">{{
                                                errors.other_police_station[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Crime Number</label>
                                            <span class="text-danger"> *</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'crime_number')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text"
                                                class="form-control form-control-sm border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.crime_number }"
                                                v-model="rowdy_case.crime_number" placeholder="Enter crime number..." />
                                            <span v-if="errors.crime_number" class="invalid-feedback">{{
                                                errors.crime_number[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Section of Law</label>
                                            <!-- <span class="text-danger">*</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'section_of_law')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                v-model="rowdy_case.section_of_law" placeholder="IPC / BNS..." />
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Case Type</label>
                                            <!-- <span class="text-danger">*</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'case_type')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <select class="form-control border-0 border-bottom form-select"
                                                :class="{ 'is-invalid': errors.case_type }"
                                                v-model="rowdy_case.case_type">
                                                <option value="">Select</option>
                                                <option value="MURDER">Murder</option>
                                                <option value="ATTEMPT_TO_MURDER">Attempt to Murder</option>
                                                <option value="CHEATING">Cheating</option>
                                                <option value="Others">Others</option>
                                            </select>
                                            <span v-if="errors.case_type" class="invalid-feedback">{{
                                                errors.case_type[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Major Head </label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'major_head')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <div class="d-flex">
                                                <search :class="{ 'is-invalid': errors?.major_head_id }"
                                                    :customClass="{ 'is-invalid': errors?.major_head_id }"
                                                    :initialize="major_head_id" id="major_head_id" label="major_head"
                                                    placeholder="Select Major Head" :data="major_heads"
                                                    @selected="getMinorHeads">
                                                </search>
                                                <a href="javascript:void(0)" class="text-danger mt-2" title="Reset"
                                                    @click="resetMajorHead()"><i class="ri-refresh-line"></i></a>
                                            </div>
                                            <span v-if="errors.major_head_id" class="invalid-feedback">{{
                                                errors.major_head_id[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Minor Head </label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'minor_head')"><i
                                                    class="ri-information-2-line"></i></a>

                                            <div class="row">
                                                <div class="col-10">
                                                <search :class="{ 'is-invalid': errors?.minor_head_id }"
                                                    :customClass="{ 'is-invalid': errors?.minor_head_id }"
                                                    :initialize="minor_head_id" id="minor_head_id" label="minor_head"
                                                    placeholder="Select Minor Head" :data="minor_heads"
                                                    @selected="minor_head => minor_head_id = minor_head">
                                                </search>                                                
                                            </div>
                                            <div class="col-1 d-flex flex-row">
                                                <a href="javascript:void(0)" class="text-danger form-control border-0" title="Reset"
                                                    @click="resetMinorHead()"><i class="ri-refresh-line"></i></a>
                                            </div>
                                            </div>
                                            <span v-if="errors.minor_head_id" class="invalid-feedback">{{
                                                errors.minor_head_id[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Victim Deceased</label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'victim_deceased')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                v-model="rowdy_case.victim_deceased"
                                                placeholder="Enter victim/deceased name..." />
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Accomplices Names</label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'accomplices_names')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                v-model="rowdy_case.accomplices_names"
                                                placeholder="Enter accomplices names..." />
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Case Status</label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'case_status')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <select class="form-control border-0 border-bottom form-select"
                                                v-model="rowdy_case.case_status"
                                                :customClass="{ 'is-invalid': errors?.case_status }">
                                                <option value="">Select</option>
                                                <option value="UI">UI</option>
                                                <option value="PT">PT</option>
                                                <option value="LPR">LPR</option>
                                                <option value="Compounded">Compounded</option>
                                                <option value="Acquitted">Acquitted</option>
                                                <option value="Convicted">Convicted</option>
                                            </select>
                                            <span v-if="errors.fir_stage" class="invalid-feedback">{{
                                                errors.fir_stage[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Court Name</label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'court_name')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                v-model="rowdy_case.court_name" placeholder="Enter court name..." />
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">CC/SC Number</label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'cc_sc_number')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                v-model="rowdy_case.cc_sc_number" placeholder="Enter court name..." />
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Next Hearing Date</label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'next_hearing_date')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="date" class="form-control border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.next_hearing_date }"
                                                v-model="rowdy_case.next_hearing_date" />
                                            <span v-if="errors.next_hearing_date" class="invalid-feedback">{{
                                                errors.next_hearing_date[0] }}</span>
                                        </div>                                        
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Warrant Issued</label>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModalBoolean"
                                                @click="getRowdyCaseEditHistory(rowdy_case.rowdies_case_id,'warrant_issued')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <select class="form-control border-0 border-bottom form-select"
                                                v-model="rowdy_case.warrant_issued"
                                                :customClass="{ 'is-invalid': errors?.warrant_issued }">
                                                <option value="">Select</option>
                                                <option :value=true>Yes</option>
                                                <option :value=false>No</option>
                                            </select>
                                            <span v-if="errors.warrant_issued" class="invalid-feedback">{{
                                                errors.warrant_issued[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button v-if="update_status" class="btn btn-primary float-end"
                                        @click="updateRowdiesCase()">Update</button>
                                    <button v-else class="btn btn-primary float-end"
                                        @click="addRowdiesCase()">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- History information modal -->
    <div class="modal fade bd-example-modal-md" tabindex="-1" id="infoModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edited History</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive small" style="overflow-y: scroll; height: 50vh;">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr class="text-center">
                                    <th>#</th>
                                    <th>NEW {{ field_name.replace(/_/g, ' ') }}</th>
                                    <th>OLD {{ field_name.replace(/_/g, ' ') }}</th>
                                    <th>Edited By</th>
                                    <th>Date Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="field_history.length == 0">
                                    <td colspan="5" class="text-center">No Records to display!</td>
                                </tr>
                                <tr v-for="(edited, key) in field_history" :key="key" v-else>
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td>{{ edited.new_values[field_name] }}</td>
                                    <td>{{ edited.old_values[field_name] }}</td>
                                    <td>{{ edited.user.name }} : {{ edited.user.role?.role }}</td>
                                    <td>{{ edited.created_at }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal for boolean values -->
	<div class="modal fade bd-example-modal-md" tabindex="-1" id="infoModalBoolean">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Edited History</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
                    <div class="table-responsive small" style="overflow-y: scroll; height: 50vh;">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr class="text-center">
                                    <th>#</th>
                                    <th>NEW {{ field_name.replace(/_/g, ' ') }}</th>
                                    <th>OLD {{ field_name.replace(/_/g, ' ') }}</th>
                                    <th>Edited By</th>
                                    <th>Date Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="field_history.length == 0">
                                    <td colspan="5" class="text-center">No Records to display!</td>
                                </tr>
                                <tr v-for="(edited, key) in field_history" :key="key" v-else>
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td class="text-center">{{ edited.new_values[field_name] ? 'Yes' : 'No' }}</td>
                                    <td class="text-center">{{ edited.old_values[field_name] ? 'Yes' : 'No' }}</td>
                                    <td>{{ edited.user.name }} : {{ edited.user.role?.role }}</td>
                                    <td>{{ edited.created_at }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

let Search = require("@/components/Search.vue").default;
export default {
    components: {
        Search
    },

    data() {
        return {
            police_station_id: '',
            major_head_id: '',
            minor_head_id: '',
            rowdy_case: {
                rowdies_case_id: '',
                rowdy_id: '',
                police_station_id: '',
                other_police_station: '',
                crime_number: '',
                fir_date: '',
                section_of_law: '',
                victim_deceased: '',
                case_status: '',
                case_type: '',
                court_name: '',
                cc_sc_number: '',
                next_hearing_date: '',
                crime_head: '',
                accomplices_names: '',
                fir_stage: '',
                warrant_issued: '',
                major_head_id: '',
                major_head: {},
                minor_head_id: '',
                minor_head: {}
            },
            police_stations: [],
            errors: [],
            rowdy: {
                rowdy_sheeter_name: ''
            },
            update_status: false,
            edited_history: [],
            new_record: '',
            edited_field_name: '',
            nav_from: '',
            path: '',
            major_heads: [],
            minor_heads: [],
            rowdy_case_edit_histories: [],			
            field_history: [],
            field_name: '',
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.nav_from = from.name;
            vm.rowdy_case.rowdy_id = to.params.rowdy_id;
            vm.getRowdy(to.params.rowdy_id);
            vm.getPoliceStations();
            vm.getMajorHeads();
            if (to.name == 'RowdyCaseUpdate') {
                vm.update_status = true;
                vm.getRowdiesCase(to.params.rowdies_case_id);
            }
            vm.path = from.path;
        })
    },

    computed: {
        isOthers() {
            let police_station = this.police_stations.find(ele => {
                return ele.police_station_id == this.police_station_id;
            })

            if (police_station) {
                if (police_station.police_station == 'Others') {
                    return true;
                } else {
                    return false;
                }
            }
        }
    },

    methods: {
        getPoliceStations() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getPoliceStations' })
                .then(response => {
                    loader.hide();
                    this.police_stations = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getRowdy(rowdy_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getRowdy', data: { rowdy_id: rowdy_id } })
                .then(response => {
                    loader.hide();
                    this.rowdy = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getRowdiesCase(rowdies_case_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getRowdiesCase', data: { rowdies_case_id: rowdies_case_id } })
                .then(response => {
                    loader.hide();
                    this.rowdy_case = response.data.data;
                    for (const key in this.rowdy_case) {
                        if (this.rowdy_case.minor_head_id == null) {
                            this.rowdy_case.minor_head_id = '';
                        }
                        if (this.rowdy_case.major_head_id == null) {
                            this.rowdy_case.major_head_id = '';
                        }
                    }
                    this.police_station_id = this.rowdy_case.police_station_id;
                    this.major_head_id = this.rowdy_case.major_head_id;
                    this.minor_head_id = this.rowdy_case.minor_head_id;
                    this.getMinorHeads(this.rowdy_case.major_head_id);
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        addRowdiesCase() {
            let vm = this;
            this.rowdy_case.police_station_id = this.police_station_id;
            this.rowdy_case.major_head_id = this.major_head_id;
            this.rowdy_case.minor_head_id = this.minor_head_id;
            if (this.rowdy_case.case_type == '') {
                this.rowdy_case.case_type = 'Others';
            }
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'addRowdiesCase', data: this.rowdy_case })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    if (this.nav_from == 'UpdateCases') {
                        this.$router.push('/update_cases_processes/' + this.rowdy_case.rowdy_id);
                    } else {
                        this.$router.push('/view_rowdy/' + this.rowdy_case.rowdy_id);
                    }
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        updateRowdiesCase() {
            let vm = this;
            this.rowdy_case.police_station_id = this.police_station_id;
            this.rowdy_case.major_head_id = this.major_head_id;
            this.rowdy_case.minor_head_id = this.minor_head_id;
            if (this.rowdy_case.case_type == null) {
                this.rowdy_case.case_type = 'Others';
            }
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateRowdiesCase', data: this.rowdy_case })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    if (this.nav_from == 'UpdateCases') {
                        this.$router.push('/update_cases_processes/' + this.rowdy_case.rowdy_id);
                    } else {
                        // this.$router.push('/view_rowdy/' + this.rowdy_case.rowdy_id);
                        this.$router.push('/all_rowdy_list');
                    }
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getMajorHeads() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getMajorHeads" };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.major_heads = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getMinorHeads(major_head_id) {
            if (!major_head_id) return;
            this.major_head_id = major_head_id;
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "getMinorHeads", data: { major_head_id: major_head_id } };
            vm.$store.dispatch("post", uri)
                .then(function (response) {
                    loader.hide();
                    vm.minor_heads = response.data.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        resetMajorHead() {
            this.major_head_id = '';
            this.minor_head_id = '';
        },

        resetMinorHead() {
            this.minor_head_id = '';
        },

        goBack() {
            this.$router.go(-1)
        },

        getRowdyCaseEditHistory(rowdies_case_id, field_name) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getRowdyCaseEditHistory', data: { rowdies_case_id: rowdies_case_id } })
                .then(response => {
                    loader.hide();
                    this.rowdy_case_edit_histories = response.data.data;
                    if(field_name == 'police_station'){
                        this.fieldHistoryPS(field_name);
                    } else {
                        this.fieldHistory(field_name);
                    }    
                })
                .catch(error => {
                    loader.hide()
                    vm.errors = error.response.data.errors
                })
        },

		fieldHistory(field_name) {
            this.field_name = field_name;
            this.field_history = this.rowdy_case_edit_histories.filter(ele => {
                return Object.hasOwn(ele.old_values, field_name) || Object.hasOwn(ele.new_values, field_name)
            })            
        },

		fieldHistoryPS(field_name) {
            this.field_name = field_name;
            const ps_id = field_name+'_id';
            this.field_history = this.rowdy_case_edit_histories.filter(ele => {
                return Object.hasOwn(ele.old_values, ps_id) || Object.hasOwn(ele.new_values, ps_id)
            })
            this.field_history.forEach(ele => {
                ele.new_values.police_station = this.police_stations.find(ps => ele.new_values.police_station_id == ps.police_station_id).police_station;
                ele.old_values.police_station = this.police_stations.find(ps => ele.old_values.police_station_id == ps.police_station_id).police_station;
            })
        },
    }
}
</script>