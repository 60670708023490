import { createRouter, createWebHashHistory } from "vue-router";
// auth
import Login from "@/views/auth/Login.vue";
//Configurations
import Divisions from "@/views/configuration/Divisions.vue";
import SubDivisions from "@/views/configuration/SubDivision.vue";
import PoliceStation from "@/views/configuration/PoliceStation.vue";
import MajorHead from "@/views/configuration/MajorHead.vue";
import MinorHead from "@/views/configuration/MinorHead.vue";
// gangs
import GangList from "@/views/gangs/GangList.vue";
import GangDetails from "@/views/gangs/GangDetails.vue";
import OppositeGang from "@/views/gangs/OppositeGang.vue";
import CreateGang from "@/views/gangs/CreateGang.vue";

// rowdy
import AllRowdyList from "@/views/rowdy/AllRowdyList.vue";
import RowdyDetails from "@/views/rowdy/RowdyDetails.vue";
import CreateRowdy from "@/views/rowdy/CreateRowdy.vue";
import RowdyCaseCreate from "@/views/rowdy/CreateCase.vue";
import RowdyProcessCreate from "@/views/rowdy/CreateProcess.vue";
import RowdyGoondaCreate from "@/views/rowdy/CreateGoonda.vue";
import ViewRowdy from "@/views/rowdy/ViewRowdy.vue";
import ParCreate from "@/views/rowdy/ParCreate.vue";
import SearchRowdy from "@/views/rowdy/SearchRowdy.vue";

// users
import UserCreate from "@/views/users/UserCreate.vue";
import UsersList from "@/views/users/UsersList.vue";
import ProfileUpdate from "@/views/users/ProfileUpdate.vue";
import Permissions from "@/views/users/Permissions.vue";
// roles
import Roles from "@/views/users/Roles.vue";
import Designation from "@/views/users/Designation.vue";

// rowdy sheeters report
import RowdySheeter from "@/views/reports/RowdySheeter.vue";
import RowdySheeterStatistics from "@/views/reports/RowdySheeterStatistics.vue";
import RowdyAlerts from "@/views/rowdy/RowdyAlerts.vue";


const routes = [
  //Authentication
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },

  // Configuration
  {
    path: "/divisions",
    name: "Divisions",
    component: Divisions,
  },
  {
    path: "/sub_divisions",
    name: "SubDivisions",
    component: SubDivisions,
  },
  {
    path: "/police_stations",
    name: "PoliceStation",
    component: PoliceStation,
  },
  {
    path: "/major_heads",
    name: "MajorHead",
    component: MajorHead,
  },
  {
    path: "/minor_heads",
    name: "MinorHead",
    component: MinorHead,
  },

  //Gangs
  {
    path: "/gang_list",
    name: "GangList",
    component: GangList,
  },
  {
    path: "/gang_details/:gang_id",
    name: "GangDetails",
    component: GangDetails,
  },
  {
    path: "/opposite_gang",
    name: "OppositeGang",
    component: OppositeGang,
  },
  {
    path: "/create_gang",
    name: "CreateGang",
    component: CreateGang,
  },
  {
    path: "/update_gang/:gang_id",
    name: "UpdateGang",
    component: CreateGang,
  },

  // rowdy
  {
    path: "/all_rowdy_list",
    name: "AllRowdyList",
    component: AllRowdyList,
  },
  {
    path: "/rowdy_details/:rowdy_id",
    name: "RowdyDetails",
    component: RowdyDetails,
  },
  {
    path: "/create_rowdy",
    name: "CreateRowdy",
    component: CreateRowdy,
  },
  {
    path: "/update_rowdy/:rowdy_id",
    name: "UpdateRowdy",
    component: CreateRowdy,
  },
  {
    path: "/update_cases_processes/:rowdy_id",
    name: "UpdateCases",
    component: CreateRowdy,
  },
  {
    path: "/rowdy_case_create/:rowdy_id",
    name: "RowdyCaseCreate",
    component: RowdyCaseCreate,
  },
  {
    path: "/rowdy_case_update/:rowdy_id/:rowdies_case_id",
    name: "RowdyCaseUpdate",
    component: RowdyCaseCreate,
  },
  {
    path: "/rowdy_process_create/:rowdy_id",
    name: "RowdyProcessCreate",
    component: RowdyProcessCreate,
  },
  {
    path: "/rowdy_process_update/:rowdy_id/:process_id",
    name: "RowdyProcessUpdate",
    component: RowdyProcessCreate,
  },
  {
    path: "/rowdy_goonda_create/:rowdy_id",
    name: "RowdyGoondaCreate",
    component: RowdyGoondaCreate,
  },
  {
    path: "/rowdy_goonda_update/:rowdy_id/:goonda_id",
    name: "RowdyGoondaUpdate",
    component: RowdyGoondaCreate,
  },
  {
    path: "/view_rowdy/:rowdy_id",
    name: "ViewRowdy",
    component: ViewRowdy,
  },
  {
    path: "/rowdy_par_create/:rowdy_id",
    name: "ParCreateCase",
    component: ParCreate,
  },
  {
    path: "/rowdy_par_update/:rowdy_id/:par_case_id",
    name: "ParUpdateCase",
    component: ParCreate,
  },
  {
    path: "/search_rowdy",
    name: "SearchRowdy",
    component: SearchRowdy,
  },
  {
    path: "/rowdy_alerts",
    name: "RowdyAlerts",
    component: RowdyAlerts,
  },

  // User Management
  {
    path: "/user_create",
    name: "UserCreate",
    component: UserCreate,
  },

  {
    path: "/user_update/:user_id",
    name: "UserUpdate",
    component: UserCreate,
  },

  {
    path: "/users_list",
    name: "UsersList",
    component: UsersList,
  },
  {
    path: "/profile_update",
    name: "ProfileUpdate",
    component: ProfileUpdate,
  },
  {
    path: "/permissions",
    name: "Permission",
    component: Permissions,
  },

  //Reports
  {
    path: "/rowdy_sheeter_report",
    name: "RowdySheeter",
    component: RowdySheeter,
  },
  {
    path: "/rowdy_sheeter_statistics",
    name: "RowdySheeterStatistics",
    component: RowdySheeterStatistics,
  },

  // Roles
  {
    path: "/roles",
    name: "Roles",
    component: Roles,
  },
  {
    path: "/designations",
    name: "Designation",
    component: Designation,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


export default router;
