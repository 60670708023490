<template>
    <div class="d-md-flex align-items-center justify-content-between gap-4 mt-2 mb-4">
        <div>
            <a class="text-dark" href="javascript:void(0)" @click="goBack()"><i
                    class="ri-arrow-left-line fs-24"></i></a>
            <h3 v-if="update_status" style="display: inline;">Update Process</h3>
            <h3 v-else style="display: inline;">Add Process</h3>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="card-body">
                        <h5 class="main-title fw-semibold mb-0">Rowdy Name: {{ rowdy.rowdy_sheeter_name }}</h5>
                        <hr>
                        <div class="tab-pane active" id="gangDetails">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row g-3">
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Rowdies Case</label>
                                            <span class="text-danger"> *</span>
                                            <select
                                                class="form-control form-control-sm border-0 border-bottom form-select"
                                                :class="{ 'is-invalid': errors.rowdies_case_id }"
                                                v-model="rowdy_process.rowdies_case_id" @change="assignCrimeNumber()">
                                                <option value="">Select</option>
                                                <option :value="cas.rowdies_case_id" v-for="cas, key in rowdies_cases">
                                                    {{ cas.police_station.police_station }}-{{ cas.crime_number }}-{{
                                                        cas.fir_date }}</option>
                                            </select>
                                            <span v-if="errors.rowdies_case_id" class="invalid-feedback">{{
                                                errors.rowdies_case_id[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Process Type</label>
                                            <span class="text-danger"> *</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getProcessEditHistory(rowdy_process.process_id,'process_type')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <select
                                                class="form-control form-control-sm border-0 border-bottom form-select"
                                                :class="{ 'is-invalid': errors.process_type }"
                                                v-model="rowdy_process.process_type">
                                                <option value="">Select</option>
                                                <option value="warrant">Warrant</option>
                                                <option value="summons">Summons</option>
                                                <option value="notices">Notices</option>
                                                <option value="proclamation">Proclamation</option>
                                            </select>
                                            <span v-if="errors.process_type" class="invalid-feedback">{{
                                                errors.process_type[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Police Station</label>
                                            <span class="text-danger"> *</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getProcessEditHistory(rowdy_process.process_id,'police_station')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <search :class="{ 'is-invalid': errors?.police_station_id }"
                                                :customClass="{ 'is-invalid': errors?.police_station_id }"
                                                :initialize="police_station_id" id="police_station_id"
                                                label="police_station" placeholder="Select police station"
                                                :data="police_stations"
                                                @selected="police_station => police_station_id = police_station">
                                            </search>
                                            <span v-if="errors.police_station_id" class="invalid-feedback">{{
                                                errors.police_station_id[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Crime Number</label>
                                            <span class="text-danger"> *</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getProcessEditHistory(rowdy_process.process_id,'crime_number')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.crime_number }" disabled="true"
                                                v-model="rowdy_process.crime_number"
                                                placeholder="Enter crime number..." />
                                            <span v-if="errors.crime_number" class="invalid-feedback">{{
                                                errors.crime_number[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Section of Law</label>
                                            <!-- <span class="text-danger">*</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getProcessEditHistory(rowdy_process.process_id,'section_of_law')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                v-model="rowdy_process.section_of_law"
                                                placeholder="Enter IPC Sections..." />
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Court Name</label>
                                            <!-- <span class="text-danger">*</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getProcessEditHistory(rowdy_process.process_id,'court_name')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                v-model="rowdy_process.court_name" placeholder="Enter court name..." />
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">CC/SC Number</label>
                                            <!-- <span class="text-danger">*</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getProcessEditHistory(rowdy_process.process_id,'cc_sc_number')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="text" class="form-control border-0 border-bottom"
                                                v-model="rowdy_process.cc_sc_number"
                                                placeholder="Enter court name..." />
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Warrant Issued Date</label>
                                            <span class="text-danger">*</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getProcessEditHistory(rowdy_process.process_id,'issued_date')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="date" class="form-control border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.issued_date }"
                                                v-model="rowdy_process.issued_date" />
                                            <span v-if="errors.issued_date" class="invalid-feedback">{{
                                                errors.issued_date[0] }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Warrant Status</label>
                                            <span class="text-danger"> *</span>
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getProcessEditHistory(rowdy_process.process_id,'status')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <select class="form-control border-0 border-bottom form-select"
                                                :class="{ 'is-invalid': errors.status }" v-model="rowdy_process.status">
                                                <option value="">Select</option>
                                                <option value="served">Served</option>
                                                <option value="unserved">Unserved</option>
                                            </select>
                                            <span v-if="errors.status" class="invalid-feedback">{{ errors.status[0]
                                            }}</span>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-label">Next Hearing Date</label>
                                            <!-- <span class="text-danger">*</span> -->
                                            &nbsp;<a v-if="update_status" href="javascript::void(0)"
                                                data-bs-toggle="modal" data-bs-target="#infoModal"
                                                @click="getProcessEditHistory(rowdy_process.process_id,'next_hearing_date')"><i
                                                    class="ri-information-2-line"></i></a>
                                            <input type="date" class="form-control border-0 border-bottom"
                                                :class="{ 'is-invalid': errors.next_hearing_date }"
                                                v-model="rowdy_process.next_hearing_date" />
                                            <span v-if="errors.next_hearing_date" class="invalid-feedback">{{
                                                errors.next_hearing_date[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button v-if="update_status" class="btn btn-primary float-end"
                                        @click="updateProcess()">Update</button>
                                    <button v-else class="btn btn-primary float-end"
                                        @click="addProcess()">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- History information modal -->
    <div class="modal fade bd-example-modal-md" tabindex="-1" id="infoModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edited History</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive small" style="overflow-y: scroll; height: 50vh;">
                        <table class="table table-sm table-bordered table-striped">
                            <thead>
                                <tr class="text-center">
                                    <th>#</th>
                                    <th>NEW {{ field_name.replace(/_/g, ' ') }}</th>
                                    <th>OLD {{ field_name.replace(/_/g, ' ') }}</th>
                                    <th>Edited By</th>
                                    <th>Date Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="field_history.length == 0">
                                    <td colspan="5" class="text-center">No Records to display!</td>
                                </tr>
                                <tr v-for="(edited, key) in field_history" :key="key" v-else>
                                    <td class="text-center">{{ key + 1 }}</td>
                                    <td>{{ edited.new_values[field_name] }}</td>
                                    <td>{{ edited.old_values[field_name] }}</td>
                                    <td>{{ edited.user.name }}</td>
                                    <td>{{ edited.created_at }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let Search = require("@/components/Search.vue").default;
export default {
    components: {
        Search
    },
    data() {
        return {
            police_station_id: '',
            rowdy_process: {
                process_id: '',
                rowdies_case_id: '',
                rowdy_id: '',
                process_type: '',
                rowdy_id: '',
                police_station_id: '',
                crime_number: '',
                section_of_law: '',
                court_name: '',
                cc_sc_number: '',
                issued_date: '',
                status: '',
                next_hearing_date: '',
            },
            police_stations: [],
            errors: [],
            rowdies_cases: [],
            rowdy: {
                rowdy_id: '',
                rowdy_sheeter_name: ''
            },
            update_status: false,
            edited_history: [],
            new_record: '',
            edited_field_name: '',
            nav_from: '',
            path: '',
            process_edit_histories: [],			
            field_history: [],
            field_name: '',
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.nav_from = from.name;
            if (to.name == 'RowdyProcessUpdate') {
                vm.update_status = true;
                vm.rowdy_process.process_id = to.params.process_id;
                vm.getProcess();
            }
            vm.rowdy_process.rowdy_id = to.params.rowdy_id;
            vm.getRowdy(to.params.rowdy_id);
            vm.getPoliceStations();
            vm.getRowdiesCases(to.params.rowdy_id);
            vm.path = from.path;
        })
    },

    methods: {
        getPoliceStations() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getPoliceStations' })
                .then(response => {
                    loader.hide();
                    this.police_stations = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getRowdy(rowdy_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getRowdy', data: { rowdy_id: rowdy_id } })
                .then(response => {
                    loader.hide();
                    this.rowdy = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        getProcess() {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getProcess', data: this.rowdy_process })
                .then(response => {
                    loader.hide();
                    this.rowdy_process = response.data.data;
                    this.police_station_id = this.rowdy_process.police_station_id;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        addProcess() {
            let vm = this;
            this.rowdy_process.police_station_id = this.police_station_id;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'addProcess', data: this.rowdy_process })
                .then(response => {
                    loader.hide();
                    this.$store.dispatch('success', response.data.message);
                    if (this.nav_from == 'UpdateCases') {
                        this.$router.push('/update_cases_processes/' + this.rowdy_process.rowdy_id);
                    } else {
                        this.$router.push('/view_rowdy/' + this.rowdy_process.rowdy_id);
                    }
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        updateProcess() {
            let vm = this;
            this.rowdy_process.police_station_id = this.police_station_id;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateProcess', data: this.rowdy_process })
                .then(response => {
                    loader.hide();
                    this.update_status = false;
                    this.$store.dispatch('success', response.data.message);
                    this.updateRowdyList();
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        updateRowdyList() {
            let vm = this;
            let loader = this.$loading.show();
            let rowdy = ''
            this.$store.dispatch('post', { uri: 'getRowdy', data: { rowdy_id: this.rowdy_process.rowdy_id } })
                .then(response => {
                    loader.hide();
                    rowdy = response.data.data;
                    if (sessionStorage.getItem('rowdy_list')) {
                        let rowdy_list = JSON.parse(sessionStorage.getItem('rowdy_list'));
                        let new_list = rowdy_list.map(ele => { return ele.rowdy_id == rowdy.rowdy_id ? Object.assign({}, rowdy) : ele });
                        sessionStorage.removeItem('rowdy_list');
                        sessionStorage.setItem('rowdy_list', JSON.stringify(new_list));
                    }
                    if (this.nav_from == 'RowdyProcessCreate') {
                        this.$router.push('/view_rowdy/' + this.rowdy_process.rowdy_id);
                    } else {
                        this.$router.push('/update_cases_processes/' + this.rowdy_process.rowdy_id);
                    }
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.resposne.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })

        },
        
        getProcessEditHistory(process_id,field_name) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getProcessEditHistory', data: { process_id: process_id } })
                .then(response => {
                    loader.hide();
                    this.process_edit_histories = response.data.data;
                    if(field_name == 'police_station'){
                        this.fieldHistoryPS(field_name);
                    } else {
                        this.fieldHistory(field_name);
                    }                                      
                })
                .catch(error => {
                    loader.hide()
                    vm.errors = error.response.data.errors
                })
        },

        fieldHistory(field_name) {
            this.field_name = field_name;
            this.field_history = this.process_edit_histories.filter(ele => {
                return Object.hasOwn(ele.old_values, field_name) || Object.hasOwn(ele.new_values, field_name)
            })
        },	

        fieldHistoryPS(field_name) {
            this.field_name = field_name;
            const ps_id = field_name+'_id';
            this.field_history = this.process_edit_histories.filter(ele => {
                return Object.hasOwn(ele.old_values, ps_id) || Object.hasOwn(ele.new_values, ps_id)
            })
            this.field_history.forEach(ele => {
                ele.new_values.police_station = this.police_stations.find(ps => ele.new_values.police_station_id == ps.police_station_id).police_station;
                ele.old_values.police_station = this.police_stations.find(ps => ele.old_values.police_station_id == ps.police_station_id).police_station;
            })
        },

        getRowdiesCases(rowdy_id) {
            let vm = this;
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'getRowdiesCases', data: { rowdy_id: rowdy_id } })
                .then(response => {
                    loader.hide();
                    this.rowdies_cases = response.data.data;
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        assignCrimeNumber() {
            let temp = this.rowdies_cases.find(ele => { return ele.rowdies_case_id == this.rowdy_process.rowdies_case_id })
            this.rowdy_process.crime_number = temp.crime_number;
            this.rowdy_process.section_of_law = temp.section_of_law;
        },

        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>