<template>
	<div class="d-md-flex align-items-center justify-content-between gap-4 mt-2 mb-4">
		<div>
			<a class="text-dark" href="javascript:void(0)" @click="goBack()"><i
					class="ri-arrow-left-line fs-24"></i></a>
			<h2 class="main-title fs-28 mb-0 ms-1" style="display: inline;">Rowdy Details</h2>
		</div>
		<!-- <button type="button" class="mt-auto btn btn-outline-primary d-flex align-items-center gap-2">
            <i class="ri-add-line fs-18 lh-1"></i>Edit
        </button> -->
		<div v-can="'rowdy.update'">
			<router-link :to="'/update_cases_processes/' + rowdy.rowdy_id"
				class="mt-auto btn btn-outline-primary d-flex align-items-center gap-2"><i
					class="ri-edit-line fs-18 lh-1"></i>Edit</router-link>
		</div>
	</div>
	<div class="col-xl-12">
		<div>
			<div class="card card-one">
				<div class="card-header d-flex justify-content-between">
					<div style="max-width: 60%;">
						<div class="p-1" v-if="rowdy.gangs.length"
							style="border-radius: 15px; background: rgba(255, 255, 150, 0.6);box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);transform: translateZ(0);">
							<span class="mx-2"
								style="font-family: 'Times New Roman', Times, serif; text-transform: uppercase;">
								<span v-for="gang, key in rowdy.gangs" :key=key>
									<a class="fw-bold text-danger" @click="viewGangDetails(gang.gang_id)"
										href="javascript:void(0)" v-if="key == 0">{{ gang.gang_name }}</a>
									<a class="fw-bold text-danger" @click="viewGangDetails(gang.gang_id)"
										href="javascript:void(0)" v-else>{{ ' | ' + gang.gang_name }}</a>
								</span>
							</span>
						</div>
					</div>
					<div>
						<button v-can="'rowdy.remarks'" class="btn btn-sm btn-primary me-2" data-bs-toggle="modal"
							data-bs-target="#viewRemarks" @click="getUserRemarks(rowdy.rowdy_id)">User Remarks</button>
						<button v-can="'rowdy.export_profile'" class="btn btn-sm btn-success mx-2"
							@click="exportRowdyProfile(rowdy.rowdy_id)">Export Profile</button>
						<button v-can="'rowdy.export_profile'" class="btn btn-sm btn-primary me-2"
							@click="printRowdyProfile(rowdy.rowdy_id)">Print Profile</button>
						<!-- <button class="btn btn-sm btn-success" @click="downloadSheets()">Export Details (excel)</button> -->
					</div>
				</div>
				<div class="card-body" style="overflow-y:scroll; height: 75vh;">
					<div class="row">
						<div class="col-sm-3">
							<div class="card mt-3">
								<a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#enlargeImageModal"
									@click="getRowdyImages(rowdy.rowdy_id)">
									<div class="image-container position-relative">
										<img :src="rowdy.rowdy_sheeter_photo ? rowdy.rowdy_sheeter_photo : '/assets/img/avatar.jpg'"
											alt="user-avatar" width="100%" id="uploadedAvatar" />
										<div class="image-text">{{ moment(rowdy.photo_taken_date) }}</div>
									</div>
								</a>
								<label class="text-center m-2">AGE : {{ rowdy.age }}</label>
							</div>
						</div>
						<div class="col-sm-9">
							<div class="row">
								<div class="col-6 my-2">
									<label for="" class="form-label">FPB Coid Number</label>
									<label for="" class="form-control">{{ rowdy.fpb_coid_number }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Rowdy Sheeter Name</label>
									<label for="" class="form-control">{{ rowdy.rowdy_sheeter_name }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Alias</label>
									<label for="" class="form-control">{{ rowdy.alias }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Father Name</label>
									<label for="" class="form-control">{{ rowdy.father_name }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Gender</label>
									<label for="" class="form-control">{{ rowdy.gender }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Mobile Number <span class="text-warning small">[ As
											on Date: {{
												rowdy.mobile_updated_as_on ? moment(rowdy.mobile_updated_as_on) :
													rowdy.updated_at
											}}]</span></label>
									<label for="" class="form-control">{{ rowdy.mobile_no }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Aadhaar</label>
									<label for="" class="form-control">{{ rowdy.aadhaar_number }}&nbsp;</label>
								</div>
								<div class="col-6 my-2">
									<label for="" class="form-label">Voter ID</label>
									<label for="" class="form-control">{{ rowdy.voter_card_no }}&nbsp;</label>
								</div>
							</div>
						</div>
						<div class="col-6 mt-2">
							<label class="form-label">Current Occupation</label><span class="text-warning small"> [ As
								Updated On: {{
									rowdy.occupation_updated_on ? moment(rowdy.occupation_updated_on) : rowdy.updated_at
								}}]</span>
							<label for="" class="form-control">{{ rowdy.present_occupation_details
							}}&nbsp;</label>
						</div>
						<div class="col-6 mt-2">
							<label class="form-label">Previous Occupation</label>
							<label for="" class="form-control">{{ rowdy.previous_occupation_details
							}}&nbsp;</label>
						</div>
						<div class="col-6 my-2">
							<label for="" class="form-label">locality</label>
							<label for="" class="form-control">{{ rowdy.locality }}&nbsp;</label>
						</div>
						<div class="col-6 my-2">
							<label for="" class="form-label">Present Address</label>
							<label for="" class="form-control">{{ rowdy.present_address }}&nbsp;</label>
						</div>
						<div class="col-6 my-2">
							<label for="" class="form-label">Permanent Address</label>
							<label for="" class="form-control">{{ rowdy.permanent_address }}&nbsp;</label>
						</div>
						<div class="col-12 my-2 mt-4">
							<label for="" class="form-label">A Brief Introduction to Rowdy</label>
							<label for="" class="form-control">{{ rowdy.remarks }}&nbsp;</label>
						</div>
						<div class="small">
							<h6 class="mt-3">BLOOD RELATIONS</h6>
							<table class="table table-hover table-sm table-bordered table-striped mb-0">
								<thead>
									<tr class="text-center">
										<th class="text-center">Sl No.</th>
										<th>Name</th>
										<th>Relation</th>
										<th>Contact</th>
										<th>Address</th>
										<th>Occupation</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="rowdy.blood_relations.length == 0">
										<td colspan="8" class="text-center">No records to display.</td>
									</tr>
									<tr v-for="blood_relative, key in rowdy.blood_relations" :key="key"
										:style="{ color: blood_relative?.is_saved ? 'black' : 'cadetblue' }" v-else>
										<td class="text-center">{{ key + 1 }}</td>
										<td>{{ blood_relative.name }}</td>
										<td>{{ blood_relative.relation }}</td>
										<td>{{ blood_relative.contact }}</td>
										<td>{{ blood_relative.address }}</td>
										<td>{{ blood_relative.occupation }}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="small">
							<h6 class="mt-3">ASSOCIATES</h6>
							<div class="table-responsive mt-3 small">
								<table class="table table-sm table-bordered">
									<thead>
										<tr class="text-center">
											<th class="text-center">Sl No.</th>
											<th>Associate Name</th>
											<th>Police Station</th>
											<th>Contact</th>
											<th>Address</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="rowdy?.associates?.length == 0">
											<td colspan="6" class="text-center">No records to display.</td>
										</tr>
										<template v-for="associate, key in rowdy.associates" :key="key" v-else>
											<tr v-if="associate?.associate_rowdy_id"
												:style="{ color: associate?.associate_id ? 'black' : 'cadetblue' }">
												<td class="text-center">{{ key + 1 }}</td>
												<td>
													<a v-if="associate.associate_rowdy_id" href="javascript:void(0)"
														title="View rowdy details."
														@click="viewRowdyDetails(associate?.associate_rowdy_id)">{{
															associate.rowdy_sheeter_name }}</a>
													<span v-else>{{ associate.rowdy_sheeter_name }}</span>
												</td>
												<td>{{ associate.police_stations }}</td>
												<td>{{ associate.mobile_no }}</td>
												<td>{{ associate.address }}</td>
											</tr>
											<tr v-else
												:style="{ color: associate?.associate_id ? 'black' : 'cadetblue' }"
												style="color: darkgoldenrod;">
												<td class="text-center">{{ key + 1 }}</td>
												<td>{{ associate.other_associate.name }}</td>
												<td></td>
												<td>{{ associate.other_associate.contact }}</td>
												<td>{{ associate.other_associate.address }}</td>
											</tr>
										</template>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="small">
						<h6 class="mt-3">CATEGORY</h6>
						<table class="table table-hover table-sm table-bordered table-striped mt-2 mb-0">
							<thead>
								<tr style="background-color: #f5f5f5;">
									<th class="text-center">SL NO</th>
									<th>Openned Date</th>
									<th>Police Station</th>
									<th>Category</th>
									<th>Closed Date</th>
									<th>Rowdy Status</th>
									<th>Where About?</th>
									<th>Occupation/<br>Business</th>
									<th>From How Long</th>
									<th>In Which Case <br> & UTP Number</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="rowdy_categories?.length" v-for="category, key in rowdy_categories"
									:key="key">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ moment(category.openned_date) }}</td>
									<td v-if="category.police_station.police_station == 'Others'">{{
										category.other_police_station }}</td>
									<td v-else style="white-space: nowrap;">{{ category.police_station.police_station }}</td>
									<td class="text-center">{{ category.category }}</td>
									<td>{{ moment(category.closed_date) }}</td>
									<td>{{ category.rowdy_status }}</td>
									<td>{{ category.where_abouts }}</td>
									<td>
										{{ category.where_abouts ==
											'Present' ? category.wa_type.occupation : '' }}
									</td>
									<td
										v-html="category.where_abouts == 'OV' ? formatHowLong(category.wa_type?.how_long) : ''">
									</td>
									<td style="white-space: nowrap;"
										v-html="category.where_abouts == 'JC' ? formatCaseUtp(category.wa_type?.case_utp) : ''">
									</td>
								</tr>
								<tr v-else>
									<td colspan="6" class="text-center">No records to display!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="small">
						<h6 class="mt-3">CASES</h6>
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th class="text-center">Sl No.</th>
									<th>&nbsp;&nbsp;&nbsp;FIR Date&nbsp;&nbsp;&nbsp;</th>
									<!-- <th>FIR Stage</th> -->
									<th>Police Station</th>
									<th>Crime Number</th>
									<th>Section of Law</th>
									<th class="text-center">Warrant Issued</th>
									<!-- <th>Crime Head</th> -->
									<th>Victim/Deceased</th>
									<th>Accomplices Names</th>
									<th>Case Status</th>
									<th>Court Name</th>
									<th class="text-center">CC/SC <br> Number</th>
									<th>Next Hearing Date</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="cases?.length" v-for="rowdy_case, key in cases">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ moment(rowdy_case.fir_date) }}</td>
									<!-- <td>{{ rowdy_case.fir_stage }}</td> -->
									<td v-if="rowdy_case.police_station?.police_station == 'Others'">{{
										rowdy_case.other_police_station }}</td>
									<td v-else style="white-space: nowrap;">{{ rowdy_case.police_station?.police_station }}</td>
									<td>{{ rowdy_case.crime_number }}</td>
									<td>{{ rowdy_case.section_of_law }}</td>
									<td class="text-center">{{ rowdy_case.warrant_issued ? 'Yes' : 'No' }}</td>
									<td>{{ rowdy_case.victim_deceased }}</td>
									<td>{{ rowdy_case.accomplices_names }}</td>
									<td>{{ rowdy_case.case_status }}</td>
									<td>{{ rowdy_case.court_name }}</td>
									<td>{{ rowdy_case.cc_sc_number }}</td>
									<td>{{ moment(rowdy_case.next_hearing_date) }}</td>
								</tr>
								<tr v-else>
									<td colspan="12" class="text-center">No records to display!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="small">
						<h6 class="mt-3">PROCESSES</h6>
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th class="text-center">SL NO</th>
									<th>Process Type</th>
									<th>Police Station</th>
									<th>Crime Number</th>
									<th>Section of Law</th>
									<th>Court Name</th>
									<th class="text-center">CC/SC <br> Number</th>
									<th>Issued Date</th>
									<th>Served/<br>Unserved</th>
									<th>Next Hearing Date</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="processes?.length" v-for="process, key in processes">
									<td>{{ key + 1 }}</td>
									<td>{{ process.process_type }}</td>
									<td class="text-nowrap">{{ process.police_station?.police_station }}</td>
									<td>{{ process.crime_number }}</td>
									<td>{{ process.section_of_law }}</td>
									<td>{{ process.court_name }}</td>
									<td>{{ process.cc_sc_number }}</td>
									<td>{{ moment(process.issued_date) }}</td>
									<td>{{ process.status }}</td>
									<td>{{ moment(process.next_hearing_date) }}</td>
								</tr>
								<tr v-else>
									<td colspan="10" class="text-center">No records to display!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- <div class="small">
						<h6 class="mt-3">GOONDA ACT/EXTERNMENT/KCOCA Act</h6>
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th>SL NO</th>
									<th>Type</th>
									<th>Police Station</th>
									<th>Person Name</th>
									<th>Father Name</th>
									<th>Address</th>
									<th>Activities</th>
									<th>Order No</th>
									<th>Approved/Rejected</th>
									<th>Which Place</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="goondas.length" v-for="goonda, key in goondas">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ goonda.type }}</td>
									<td>{{ goonda.police_station.police_station }}</td>
									<td>{{ goonda.person_name }}</td>
									<td>{{ goonda.father_name }}</td>
									<td>{{ goonda.address }}</td>
									<td>{{ goonda.activities }}</td>
									<td>{{ goonda.order_no }}</td>
									<td>{{ goonda.approved_status }}</td>
									<td>{{ goonda.which_place }}</td>
								</tr>
								<tr v-else>
									<td colspan="10 " class="text-center">No records to display!</td>
								</tr>
							</tbody>
						</table>
					</div> -->
					<div class="small">
						<h6 class="mt-3">GOONDA Act</h6>
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th>SL NO</th>
									<th>Police Station</th>
									<th>Person Name</th>
									<th>Father Name</th>
									<th>Address</th>
									<th>Activities</th>
									<th>Order No</th>
									<th>Approved/Rejected</th>
									<th>Added By PS</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="grouped_goondas?.Goonda" v-for="goonda, key in grouped_goondas?.Goonda">
									<td class="text-center">{{ key + 1 }}</td>
									<td style="white-space: nowrap;">{{ goonda.police_station.police_station }}</td>
									<td>{{ goonda.person_name }}</td>
									<td>{{ goonda.father_name }}</td>
									<td>{{ goonda.address }}</td>
									<td>{{ goonda.activities }}</td>
									<td>{{ goonda.order_no }}</td>
									<td>{{ goonda.approved_status }}</td>
									<td style="white-space: nowrap;">{{ goonda.created_by.name }}</td>
								</tr>
								<tr v-else>
									<td colspan="8 " class="text-center">No records to display!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="small">
						<h6 class="mt-3">EXTERNMENT</h6>
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th>SL NO</th>
									<th>Police Station</th>
									<th>Person Name</th>
									<th>Father Name</th>
									<th>Address</th>
									<th>Activities</th>
									<th>Order No</th>
									<th>Which Place</th>
									<th>Added By PS</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="grouped_goondas.Externment?.length"
									v-for="goonda, key in grouped_goondas?.Externment">
									<td class="text-center">{{ key + 1 }}</td>
									<td style="white-space: nowrap;">{{ goonda.police_station.police_station }}</td>
									<td>{{ goonda.person_name }}</td>
									<td>{{ goonda.father_name }}</td>
									<td>{{ goonda.address }}</td>
									<td>{{ goonda.activities }}</td>
									<td>{{ goonda.order_no }}</td>
									<td>{{ goonda.which_place }}</td>
									<td style="white-space: nowrap;">{{ goonda.created_by.name }}</td>
								</tr>
								<tr v-else>
									<td colspan="8 " class="text-center">No records to display!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="small">
						<h6 class="mt-3">KCOCA Act</h6>
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th>SL NO</th>
									<th>Police Station</th>
									<th>Order No.</th>
									<th>Crime Number</th>
									<th>Under Section</th>
									<th>Activities</th>
									<th>Added By PS</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="grouped_goondas.KCOCA_Act?.length"
									v-for="goonda, key in grouped_goondas?.KCOCA_Act">
									<td class="text-center">{{ key + 1 }}</td>
									<td style="white-space: nowrap;">{{ goonda.police_station.police_station }}</td>
									<td>{{ goonda.order_no }}</td>
									<td>{{ goonda.cr_number }}</td>
									<td>{{ goonda.under_section }}</td>
									<td>{{ goonda.activities }}</td>
									<td style="white-space: nowrap;">{{ goonda.created_by.name }}</td>
								</tr>
								<tr v-else>
									<td colspan="10 " class="text-center">No records to display!</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="small">
						<h6 class="mt-3">PAR CASES</h6>
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead>
								<tr class="text-center">
									<th rowspan="2" class="text-center">Sl No.</th>
									<th rowspan="2">Police Station</th>
									<th rowspan="2">PAR No.</th>
									<th rowspan="2">Year</th>
									<th rowspan="2">Section<br>Of Law</th>
									<th rowspan="2">PAR Date</th>
									<th colspan="2">Bound Over Date</th>
									<th rowspan="2">Surety Details</th>
									<th rowspan="2">If Violated</th>
									<th rowspan="2">Date</th>
								</tr>
								<tr class="text-center">
									<th>From</th>
									<th>To</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="par_case, key in par_cases" v-if="par_cases?.length">
									<td class="text-center">{{ key + 1 }}</td>
									<td class="text-nowrap">{{ par_case.police_station?.police_station }}</td>
									<td>{{ par_case.par_no }}</td>
									<td>{{ par_case.year }}</td>
									<td>{{ par_case.section_of_law }}</td>
									<td>{{ moment(par_case.par_date) }}</td>
									<td>{{ moment(par_case.bound_over_from) }}</td>
									<td>{{ moment(par_case.bound_over_to) }}</td>
									<td>{{ par_case.surety_details }}</td>
									<td>{{ par_case.if_violated }}</td>
									<td>{{ moment(par_case.violated_date) }}</td>
								</tr>
								<tr v-else>
									<td colspan="13" class="text-center">No records to display!...</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- View remarks modal -->
	<div class="modal fade" id="viewRemarks" tabindex="-1" aria-labelledby="viewRemarksModal" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="viewRemarksModal">Remarks</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12">
									<textarea name="" class="form-control" id="" cols="30" rows="2"
										placeholder="Enter user remarks" v-model="user_remarks"></textarea>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12">
									<button v-if="edit_remarks" class="btn btn-sm float-end btn-primary mt-2"
										@click="updateUserRemarks()">Update
										Remarks</button>
									<button v-else class="btn btn-sm float-end btn-primary mt-2"
										@click="addUserRemarks()">Add
										Remarks</button>
								</div>
							</div>
							<div class="table-responsive mt-2">
								<table class="table table-sm table-bordered table-striped">
									<thead>
										<tr class="text-center">
											<th class="text-center">SL NO</th>
											<th>LAST UPDATED</th>
											<th>USER</th>
											<th>REMARKS</th>
											<th>ACTIONS</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="remarks?.length" v-for="remark, key in remarks" :key="key" class="">
											<td width="10%" class="text-center">{{ key + 1 }}</td>
											<td class="text-center">{{ moment(remark.updated_at) }}
											</td>
											<td>{{ remark.user.name }}</td>
											<td width="50%">{{ remark.remarks }}</td>
											<td class="text-center" width="15%" style="vertical-align: middle;">
												<nav>
													<button v-if="$store.getters.user.user_id == remark.user_id"
														class="btn btn-sm btn-outline-danger me-2"
														@click="deleteUserRemarks(remark.user_remark_id)"><i
															class="ri-chat-delete-line"
															title="Delete Remarks"></i></button>
													<button v-if="$store.getters.user.user_id == remark.user_id"
														class="btn btn-sm btn-outline-primary"
														@click="editRemarks(remark)"><i class="ri-pencil-line"
															title="Edit Remarks"></i></button>
												</nav>
											</td>
										</tr>
										<tr v-else class="">
											<td class="text-center" colspan="5">No records to display!</td>
										</tr>
									</tbody>
								</table>
							</div>

						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						@click="discardRemarks()">Close</button>
					<!-- <button type="button" class="btn btn-primary">Add Remarks</button> -->
				</div>
			</div>
		</div>
	</div>
	<!-- Enlarge image -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="enlargeImageModal">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Rowdy Images</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 d-flex justify-content-around">
							<label class="form-label text-primary">Uploaded By: {{ shift.created_by?.name}}</label>
						</div>
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="this.shift.index > 0" class="btn btn-sm btn-outline-primary float-start"
								@click="shiftLeft()"> <i class="ri-arrow-left-line"></i> </button>
						</div>
						<div class="col-8">
							<img class="modal-img" :src="shift.image" alt="" />
							<div class="text-overlay">{{ moment(shift.photo_taken_date) }}</div>
						</div>
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="shift.index <= rowdy_images.length - 2"
								class="btn btn-sm btn-outline-primary float-end" @click="shiftRight()"> <i
									class="ri-arrow-right-line"></i> </button>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';
export default {
	data() {
		return {
			rowdy: {
				rowdy_id: '',
				rowdy_sheeter_name: '',
				age: '',
				fpb_coid_number: '',
				alias: '',
				father_name: '',
				present_address: '',
				permanent_address: '',
				remarks: '',
				aadhaar_number: '',
				mobile_no: '',
				blood_relations: [],
				gangs: []
			},
			rowdy_categories: [],
			cases: [],
			par_cases: [],
			processes: [],
			goondas: [],
			grouped_goondas: {},
			r_paths: [],
			path: '',
			remarks: [],
			user_remarks: '',
			edit_remarks: false,
			user_remark_id: '',
			rowdy_image: '',
			rowdy_images: [],
			shift: {
				index: 0,
				image: '',
				photo_taken_date: ''
			},
		}
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			if (sessionStorage.getItem('r_paths')) {
				vm.r_paths = JSON.parse(sessionStorage.getItem('r_paths'));
				if (vm.r_paths[0]) {
					vm.path = vm.r_paths[0];
					vm.r_paths[0] = null;
				}
			} else {
				vm.r_paths[0] = from.path;
				vm.path = from.path;
				sessionStorage.setItem('r_paths', JSON.stringify(vm.r_paths));
			}
			vm.getRowdy(to.params.rowdy_id);
		})
	},

	computed: {
		getGangNames() {
			let gang_names = this.rowdy.gangs.map(ele => ele.gang_name);
			return gang_names.join(' | ');
		}
	},
	watch: {
		'$route.params.rowdy_id': 'fetchRowdyDetails', // Watch for changes in rowdy_id
	},
	methods: {
		fetchRowdyDetails() {
			this.getRowdy(this.$route.params.rowdy_id);
		},

		getRowdy(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdy', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.rowdy = response.data.data;
					if (vm.rowdy.blood_relations == null) {
						vm.rowdy.blood_relations = [];
					}
					this.rowdy_categories = this.rowdy.rowdy_categories;
					this.getCases(this.rowdy.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getCases(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getCases', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.cases = response.data.data;
					this.getProcesses(this.rowdy.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getProcesses(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getProcesses', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.processes = response.data.data;
					this.getGroupedGoondas(this.rowdy.rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getGroupedGoondas(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getGroupedGoondas', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.grouped_goondas = response.data.grouped_goondas;
					this.getParCases(rowdy_id);
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getParCases(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getParCases', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.par_cases = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		downloadSheets() {
			window.open(this.$store.getters.downloadUrl + "downloadSheets?rowdy_id=" + this.rowdy.rowdy_id);
		},

		exportRowdiesCasesPdf() {
			window.open(this.$store.getters.downloadUrl + "exportRowdiesCasesPdf?rowdy_id=" + this.rowdy.rowdy_id);
		},

		printViewPdf() {
			window.open(this.$store.getters.downloadUrl + "rowdyViewPdf?rowdy_id=" + this.rowdy.rowdy_id);
		},

		getUserRemarks(rowdy_id) {
			this.rowdy_id = rowdy_id;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getUserRemarks', data: { rowdy_id: this.rowdy_id } })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		addUserRemarks() {
			if (this.user_remarks == '') {
				alert('Remarks field cannot be empty!');
				return;
			}
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'addUserRemarks', data: { rowdy_id: this.rowdy_id, remarks: this.user_remarks } })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		deleteUserRemarks(user_remark_id) {
			if (!confirm('This remark will be deleted permanantly!')) {
				return;
			}
			let vm = this;
			let loader = this.$loading.show();
			let data = { rowdy_id: this.rowdy_id, user_remark_id: user_remark_id };
			this.$store.dispatch('post', { uri: 'deleteUserRemarks', data: data })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
				})
				.catch(error => {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		editRemarks(remark) {
			this.edit_remarks = true;
			this.user_remarks = remark.remarks;
			this.user_remark_id = remark.user_remark_id;
		},

		updateUserRemarks() {
			this.edit_remarks = false;
			let vm = this;
			let data = { rowdy_id: this.rowdy_id, remarks: this.user_remarks, user_remark_id: this.user_remark_id }
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'updateUserRemarks', data: data })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		discardRemarks() {
			this.user_remarks = '';
		},

		moment(date) {
			if (date) {
				return moment(date).format('DD-MM-YYYY');
			}
		},

		formatHowLong(obj) {
			let str = '';
			if (obj) {
				str = 'Days: <span class=\"text-primary\">' + obj.days + '</span><br>Months: <span class=\"text-primary\">' + obj.months + '</span><br>Years : <span class=\"text-primary\">' + obj.years + '</span>';
			}
			return str;
		},

		formatCaseUtp(obj) {
			let str = '';
			if (obj) {
				str = 'Station/Crime/No/U/s: <span class=\"text-primary\""<br>' + obj.crime_number + '</span><br>UTP Number: <span class=\"text-primary\">' + obj.utp_number + '</span>';
			}
			return str;
		},

		getRowdyImages(rowdy_id) {
			let vm = this;
			let loader = vm.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdyImages', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.rowdy_images = response.data.data;
					if (this.rowdy_images.length) {
						this.shift.index = 0;
						this.shift.image = this.rowdy_images[0].image;
						this.shift.photo_taken_date = this.rowdy_images[0].photo_taken_date
						this.shift.created_by = this.rowdy_images[0].created_by;
					}
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		shiftLeft() {
			if (this.rowdy_images.length) {
				this.shift.index--;
				this.shift.image = this.rowdy_images[this.shift.index].image;
				this.shift.photo_taken_date = this.rowdy_images[this.shift.index].photo_taken_date;
				this.shift.created_by = this.rowdy_images[this.shift.index].created_by;
			}
		},

		shiftRight() {
			if (this.rowdy_images.length) {
				this.shift.index++;
				this.shift.image = this.rowdy_images[this.shift.index].image;
				this.shift.photo_taken_date = this.rowdy_images[this.shift.index].photo_taken_date;
				this.shift.created_by = this.rowdy_images[this.shift.index].created_by;
			}
		},

		goBack() {
			this.$router.go(-1)
		},

		printRowdyProfile(rowdy_id) {
			let loader = this.$loading.show();
			const printPDFWithAuth = async () => {
				try {
					const response = await axios.post(this.$store.getters.downloadUrl + 'api/rowdyViewPdf',
						{
							rowdy_id: rowdy_id
						},
						{
							responseType: 'blob',
							headers: {
								Authorization: 'Bearer ' + this.$store.getters.token
							}
						}
					);

					const blob = new Blob([response.data], { type: 'application/pdf' });
					const url = URL.createObjectURL(blob);

					const iframe = document.createElement('iframe');
					iframe.style.position = 'absolute';
					iframe.style.width = '0px';
					iframe.style.height = '0px';
					iframe.style.border = 'none';
					iframe.src = url;
					document.body.appendChild(iframe);
					iframe.onload = () => {
						iframe.contentWindow.focus();
						iframe.contentWindow.print();
						loader.hide();
						setTimeout(() => {
							document.body.removeChild(iframe);
						}, 60000)
					};
				} catch (error) {
					loader.hide();
					console.error('Error printing the file:', error);
					alert('There was an issue printing the file.');
				}
			};
			printPDFWithAuth();
		},

		exportRowdyProfile(rowdy_id) {
			let loader = this.$loading.show();
			const downloadPDFWithAuth = async () => {
				try {
					const response = await axios.post(this.$store.getters.downloadUrl + 'api/rowdyViewPdf',
						{
							rowdy_id: rowdy_id
						},
						{
							responseType: 'blob',
							headers: {
								Authorization: 'Bearer ' + this.$store.getters.token
							}
						}
					);
					loader.hide();
					const blob = new Blob([response.data], { type: 'application/pdf' });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = `rowdy_profile_${Date.now()}.pdf`;
					link.click();
					URL.revokeObjectURL(link.href);
				} catch (error) {
					loader.hide();
					console.error('Error downloading the file:', error);
					alert('There was an issue downloading the file.');
				}
			};
			downloadPDFWithAuth();
		},

		viewRowdyDetails(rowdy_id) {
			this.$router.push(`/view_rowdy/${rowdy_id}`);
		},

		viewGangDetails(gang_id) {
			this.$router.push(`/gang_details/${gang_id}`);
		}
	}
}
</script>
<style>
mark {
	background: orange;
	color: black;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: rgb(214, 211, 211);
	border-style: solid;
	border-width: 1px;
	height: 35px !important;
	vertical-align: middle;
}

.modal-body .col-8 {
	position: relative;
}

.modal-body .modal-img {
	width: 100%;
	height: auto;
}

.text-overlay {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	color: white;
	font-size: 18px;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 5px;
	border-radius: 5px;
}

.image-container {
	position: relative;
	width: 100%;
}

.image-text {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	color: white;
	font-size: 16px;
	font-weight: bold;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 5px;
	border-radius: 5px;
}
</style>