<template>
	<div class="row g-3">
		<div class="col-xl-12">
			<div class="card card-one">
				<div class="card-header d-flex justify-content-between">
					<h5 class="text-primary pt-2"> Rowdy Sheeters : {{ meta.totalRows }}, Rowdy Sheets : {{ rowdy_sheets
					}}</h5>
					<div>
						<button class="btn btn-sm btn-primary me-2" @click="clearSelection()">Clear</button>
						<button class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#searchModal"
							ref="search_button">Search</button>
						<button class="btn btn-sm btn-success mx-2" @click="downloadSearchRowdies()">Export
							(excel)</button>
					</div>
				</div>
				<div class="card-body">
					<div class="small p-2">
						<table class="table table-sm table-bordered border-primary ">
							<tbody>
								<tr class="text-center fw-bold">
									<td colspan="9">Searched Fields</td>
								</tr>
								<tr class="text-center fw-bold">
									<td>Personal</td>
									<td>Divisions</td>
									<td>Category</td>
									<td>Cases</td>
									<td>Processes</td>
									<td>Goonda</td>
									<td>Externment</td>
									<td>KCOCA Act</td>
									<td>PAR cases</td>
									<td>IR Reports</td>
								</tr>
								<tr>
									<td v-html="personal_fields.join('<br>')"></td>
									<td v-html="division_fields.join('<br>')"></td>
									<td v-html="category_fields.join('<br>')"></td>
									<td v-html="cases_fields.join('<br>')"></td>
									<td v-html="processes_fields.join('<br>')"></td>
									<td v-html="goonda_act_fields.join('<br>')"></td>
									<td v-html="externment_act_fields.join('<br>')"></td>
									<td v-html="kcoca_act_fields.join('<br>')"></td>
									<td v-html="par_cases_fields.join('<br>')"></td>
									<td v-html="ir_reports_fields.join('<br>')"></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="table-responsive m-2" ref="rowdy_scroll" style="overflow-y:scroll; height: 60vh;"
						@scroll="onScroll($event)">
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead class="freeze-head text-center">
								<tr style="background-color: #f5f5f5;">
									<th class="text-center" rowspan="2" v-can="'rowdy.update'">Edit</th>
									<th class="text-center" rowspan="2">Sl No.</th>
									<th class="text-center" rowspan="2">Photo</th>
									<th @click="sort('rowdy_sheeter_name')" rowspan="2">Rowdy Names
										<span>
											<i v-if="meta.keyword == 'rowdy_sheeter_name' && meta.order_by == 'asc'"
												class="ri-sort-alphabet-asc"></i>
											<i v-else-if="meta.keyword == 'rowdy_sheeter_name' && meta.order_by == 'desc'"
												class="ri-sort-alphabet-desc"></i>
											<i v-else class="ri-arrow-up-down-line"></i>
										</span>
									</th>
									<th rowspan="2">Alias</th>
									<th @click="sort('father_name')" rowspan="2">Father Name
										<span>
											<i v-if="meta.keyword == 'father_name' && meta.order_by == 'asc'"
												class="ri-sort-alphabet-asc"></i>
											<i v-else-if="meta.keyword == 'father_name' && meta.order_by == 'desc'"
												class="ri-sort-alphabet-desc"></i>
											<i v-else class="ri-arrow-up-down-line"></i>
										</span>
									</th>
									<th rowspan="2" class="th-lg">Rowdy Age</th>
									<th rowspan="2" class="th-lg">Gender</th>
									<th rowspan="2" class="th-lg">Locality</th>
									<th rowspan="2" class="th-lg text-center">Category<br>Police
										Station</th>
									<th rowspan="2" class="th-lg text-center">Created<br>Police
										Station</th>
									<th rowspan="2">Mobile</th>
									<th rowspan="2">Aadhaar</th>
									<th rowspan="2">
										IR Reports
										<!-- <div class="form-check small text-success">
											<input class="form-check-input" type="checkbox" v-model="meta.with_ir_reports"
											@change="getRowdyList()" />
											<label class="form-check-label" for=""> Show Only IR </label>
										</div> -->
									</th>
									<th style="vertical-align: bottom !important" class="text-center" colspan="4">No. Of
										Cases</th>
									<th rowspan="2" class="text-center">Pending <br> Warrants/ <br>Proclamations</th>
									<th style="vertical-align: bottom !important" class="text-center" colspan="3">
										Goonda Act / Externment / KCOCA Act
									</th>
									<th rowspan="2">PAR<br>Cases</th>
									<th class="text-center" rowspan="2">Delete</th>
								</tr>
								<tr style="background-color: #f5f5f5;" class="text-center">
									<th style="vertical-align: top !important">MURDER</th>
									<th style="vertical-align: top !important">ATTEMPT<br>TO MURDER</th>
									<th style="vertical-align: top !important">Other <br> Cases</th>
									<th style="vertical-align: top !important">Total</th>
									<th style="vertical-align: top !important">Goonda<br>Act</th>
									<th style="vertical-align: top !important">Externment</th>
									<th style="vertical-align: top !important">KCOCA<br>Act</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="rowdy, key in rowdy_list" :key="key">
									<tr>
										<td class="text-center" v-can="'rowdy.update'">
											<nav>
												<router-link :to="'/update_cases_processes/' + rowdy.rowdy_id"
													href="javascript:void(0)" class="text-primary me-2"
													title="Edit Rowdy">Edit</router-link><br>

												<a href="javascript:void(0)" v-can="'rowdy.remarks'"
													class="text-success me-2" title="User Remarks"
													data-bs-toggle="modal" data-bs-target="#viewRemarks"
													@click="getUserRemarks(rowdy.rowdy_id)">Remark</a><br>
												<span class="text-danger">{{ rowdy.remarks_count }}</span>
											</nav>
										</td>
										<td class="text-center">{{ meta.from + key }}
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#enlargeImageModal"
												@click="getRowdyImages(rowdy.rowdy_id)">
												<img :src="rowdy.rowdy_sheeter_photo" alt="" width="40px">
											</a>
										</td>
										<td style="text-transform: uppercase;">
											<router-link :to="'/view_rowdy/' + rowdy.rowdy_id"
												class="text-primary me-2">
												<Highlighter class="my-highlight" highlightClassName="highlight"
													:searchWords="rowdy_sheeter_name" :autoEscape="true"
													:textToHighlight="rowdy.rowdy_sheeter_name" />
											</router-link>
										</td>
										<td style="text-transform: uppercase;">
											<Highlighter class="my-highlight" highlightClassName="highlight"
												:searchWords="alias" :autoEscape="true"
												:textToHighlight="rowdy.alias" />
										</td>
										<td>{{ rowdy.father_name }}</td>
										<td class="text-center">{{ rowdy.age }}
										</td>
										<td class="text-center">{{ rowdy.gender
										}}</td>
										<td class="text-center text-nowrap">
											{{ rowdy.locality }}
										</td>
										<td class="small text-nowrap text-uppercase">
											<span v-html="policeStationCategories(rowdy.rowdy_categories)"></span>
										</td>
										<td class="small text-center text-nowrap text-uppercase">
											{{ rowdy?.user?.police_station }}
										</td>
										<td>{{ rowdy.mobile_no }}</td>
										<td>{{ rowdy.aadhaar_number }}</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												v-if="rowdy.ir_reports_count" @click="getIRReports(rowdy.rowdy_id)"
												class="btn btn-sm btn-outline-success"
												data-bs-target="#ir_report_download">
												<i class="ri-download-line"></i>
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#ipcModal"
												@click="getIpcCases(rowdy.rowdy_id, 'MURDER')">{{
													rowdy.count_murder_cases }}</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#ipcModal"
												@click="getIpcCases(rowdy.rowdy_id, 'ATTEMPT_TO_MURDER')">{{
													rowdy.count_attempt_to_murder_cases }}</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#ipcModal"
												@click="getIpcCases(rowdy.rowdy_id, 'Others')">{{ rowdy.no_of_cases -
													(rowdy.count_murder_cases + rowdy.count_attempt_to_murder_cases) }}</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#ipcModal"
												@click="getIpcCases(rowdy.rowdy_id, 'total')">{{ rowdy.cases_type_total
												}}</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#pendingProcesses"
												@click="getPendingProcesses(rowdy.rowdy_id)">
												{{ rowdy.process_pending_count }}
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#goonda_externment_kcoca_modal"
												@click="getGoondaExternmentKcoca(rowdy.rowdy_id, 'Goonda Act')">
												{{ rowdy.goonda_count }}
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#goonda_externment_kcoca_modal"
												@click="getGoondaExternmentKcoca(rowdy.rowdy_id, 'Externment')">
												{{ rowdy.externment_count }}
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#goonda_externment_kcoca_modal"
												@click="getGoondaExternmentKcoca(rowdy.rowdy_id, 'KCOCA Act')">
												{{ rowdy.kcoca_act_count }}
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#par_cases_modal" @click="getParCases(rowdy.rowdy_id)">
												{{ rowdy.par_cases_count }}
											</a>
										</td>
										<td class="text-center" v-can="'rowdy.delete'">
											<nav v-if="rowdy.can_edit">
												<a href="javascript:void(0)" class="text-danger"
													@click="deleteRowdy(rowdy.rowdy_id)" title="Delete Rowdy"><i
														class="ri-delete-bin-6-line fs-18 lh-1"></i></a>
											</nav>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
				<div class="card-footer">
					<Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
						@pagechanged="onPageChange" />
				</div>
			</div>
		</div>
	</div>
	<!-- View remarks modal -->
	<div class="modal fade" id="viewRemarks" tabindex="-1" aria-labelledby="viewRemarksModal" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="viewRemarksModal">Remarks</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12">
									<textarea name="" class="form-control" id="" cols="30" rows="2"
										placeholder="Enter user remarks" v-model="user_remarks"></textarea>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12">
									<button v-if="edit_remarks" class="btn btn-sm float-end btn-primary mt-2"
										@click="updateUserRemarks()">Update
										Remarks</button>
									<button v-else class="btn btn-sm float-end btn-primary mt-2"
										@click="addUserRemarks()">Add
										Remarks</button>
								</div>
							</div>
							<div class="table-responsive mt-2">
								<table class="table table-sm table-bordered table-striped">
									<thead>
										<tr class="text-center">
											<th class="text-center">SL NO</th>
											<th>LAST UPDATED</th>
											<th>USER</th>
											<th>REMARKS</th>
											<th>ACTIONS</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="remarks.length" v-for="remark, key in remarks" :key="key" class="">
											<td width="10%" class="text-center">{{ key + 1 }}</td>
											<td class="text-center">{{ moment(remark.updated_at).format('DD-MM-YYYY') }}
											</td>
											<td>{{ remark.user.name }}</td>
											<td width="50%">{{ remark.remarks }}</td>
											<td class="text-center" width="15%" style="vertical-align: middle;">
												<nav>
													<button v-if="$store.getters.user.user_id == remark.user_id"
														class="btn btn-sm btn-outline-danger me-2"
														@click="deleteUserRemarks(remark.user_remark_id)"><i
															class="ri-chat-delete-line"></i></button>
													<button v-if="$store.getters.user.user_id == remark.user_id"
														class="btn btn-sm btn-outline-primary"
														@click="editRemarks(remark)"><i
															class="ri-pencil-line"></i></button>
												</nav>
											</td>
										</tr>
										<tr v-else class="">
											<td class="text-center" colspan="5">No records to display!</td>
										</tr>
									</tbody>
								</table>
							</div>

						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						@click="discardRemarks()">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!-- Goonda act / externment modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="ipcModal">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">IPC {{ ipc }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead class="text-center">
								<tr>
									<th>SL NO</th>
									<th>Police Station</th>
									<th>Crime Number</th>
									<th>Section of Law</th>
									<th>FIR Stage / <br> Warrant Issued</th>
									<th>Victim/Deceased</th>
									<th>Accomplices Names</th>
									<th>Case Status</th>
									<th>Court Name</th>
									<th>CC / SC Number</th>
									<th>Next Hearing Date</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="ipc_case, key in ipc_cases" v-if="ipc_cases.length">
									<td>{{ key + 1 }}</td>
									<td v-if="ipc_case.police_station?.police_station == 'Others'">{{
										ipc_case.other_police_station }}</td>
									<td v-else>{{ ipc_case.police_station?.police_station }}</td>
									<td>{{ ipc_case.crime_number }}</td>
									<td>{{ ipc_case.section_of_law }}</td>
									<td>{{ ipc_case.warrant_issued ? 'Yes' : 'No' }}</td>
									<td>{{ ipc_case.victim_deceased }}</td>
									<td>{{ ipc_case.accomplices_names }}</td>
									<td>{{ ipc_case.case_status }}</td>
									<td>{{ ipc_case.court_name }}</td>
									<td>{{ ipc_case.cc_sc_number }}</td>
									<td>{{ ipc_case.next_hearing_date }}</td>
									<td><button class="btn btn-sm btn-primary"
											@click="editIpcCase(ipc_case.rowdy_id, ipc_case.rowdies_case_id)"><i
												class="ri-pencil-line"></i></button></td>
								</tr>
								<tr v-else>
									<td colspan="12" class="text-center">No records to display!...</td>
								</tr>
							</tbody>
						</table>
					</div>

				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="ipc_modal_close">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!-- pending processes modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="pendingProcesses">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">IPC {{ ipc }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="small mt-2">
						<div class="table-responsive">
							<table class="table table-sm table-bordered table-striped">
								<thead class="text-center">
									<tr>
										<th class="text-center">Sl No.</th>
										<th>Process Type</th>
										<th>Police Station</th>
										<th>Crime Number</th>
										<th>Section of Law</th>
										<th>Court Name</th>
										<th>CC / SC Number</th>
										<th>Issued Date</th>
										<th>Served/Unserved</th>
										<th>Next Hearing Date</th>
										<th>Edit</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="process, key in pending_processes" v-if="pending_processes.length">
										<td class="text-center">{{ key + 1 }}</td>
										<td>{{ process.process_type }}</td>
										<td>{{ process.police_station?.police_station }}</td>
										<td>{{ process.crime_number }}</td>
										<td>{{ process.section_of_law }}</td>
										<td>{{ process.court_name }}</td>
										<td>{{ process.cc_sc_number }}</td>
										<td>{{ process.issued_date }}</td>
										<td>{{ process.status }}</td>
										<td>{{ process.next_hearing_date }}</td>
										<td>
											<a href="javascript:void(0)"
												@click="editProcess(process.process_id, process.rowdy_id)"><i
													class="ri-edit-line fs-18 lh-1" title="Edit rowdy"></i></a>
										</td>
									</tr>
									<tr v-else>
										<td colspan="11" class="text-center">No records to display!...</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="ipc_modal_close">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!-- Goonda act, Externment act and KCOCA act modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="goonda_externment_kcoca_modal">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title"> {{ goonda_title }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead class="text-center">
								<tr>
									<th>SL NO</th>
									<th>Police Station</th>
									<th>Person Name</th>
									<th>Father Name</th>
									<th>Address</th>
									<th>Activities</th>
									<th>Order No.</th>
									<th>Approved / Rejected</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="goonda, key in goondas" v-if="goondas.length">
									<td class="text-center">{{ key + 1 }}</td>
									<td v-if="goonda.police_station?.police_station == 'Others'">{{
										goonda.other_police_station }}</td>
									<td v-else>{{ goonda.police_station?.police_station }}</td>
									<td style="text-transform: uppercase;">{{ goonda.person_name }}</td>
									<td>{{ goonda.father_name }}</td>
									<td>{{ goonda.address }}</td>
									<td class="text-center">{{ goonda.activities }}</td>
									<td>{{ goonda.order_no }}</td>
									<td class="text-center">{{ goonda.approved_status }}</td>
									<td class="text-center">
										<button class="btn btn-sm btn-primary" v-if="goonda.can_edit_goonda"
											@click="editGoondaCase(goonda.rowdy_id, goonda.goonda_id)"><i
												class="ri-pencil-line"></i></button>
									</td>
								</tr>
								<tr v-else>
									<td colspan="13" class="text-center">No records to display!...</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="goonda_modal_close">Close</button>
				</div>
			</div>
		</div>
	</div>

	<!-- PAR cases modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="par_cases_modal">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title"> {{ goonda_title }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead>
								<tr class="text-center">
									<th rowspan="2" class="text-center">Sl No.</th>
									<th rowspan="2">Police Station</th>
									<th rowspan="2">PAR No.</th>
									<th rowspan="2">Year</th>
									<th rowspan="2">Section<br>Of Law</th>
									<th rowspan="2">PAR Date</th>
									<th colspan="2">Bound Over Date</th>
									<th rowspan="2">Surety Details</th>
									<th rowspan="2">If Violated</th>
									<th rowspan="2">Date</th>
									<th v-can="'par_case.delete'" rowspan="2">Action</th>
								</tr>
								<tr class="text-center">
									<th>From</th>
									<th>To</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="par_case, key in par_cases" v-if="par_cases.length">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ par_case.police_station?.police_station }}</td>
									<td>{{ par_case.par_no }}</td>
									<td>{{ par_case.year }}</td>
									<td>{{ par_case.section_of_law }}</td>
									<td>{{ moment(par_case.par_date) }}</td>
									<td>{{ moment(par_case.bound_over_from) }}</td>
									<td>{{ moment(par_case.bound_over_to) }}</td>
									<td>{{ par_case.surety_details }}</td>
									<td>{{ par_case.if_violated }}</td>
									<td>{{ moment(par_case.violated_date) }}</td>
									<td v-can="'par_case.delete'" class="text-center"><button
											v-if="par_case.can_edit_par_case" class="btn btn-sm btn-primary"
											@click="editParCase(par_case.rowdy_id, par_case.par_case_id)"><i
												class="ri-pencil-line" title="Delete PAR case"></i></button>
									</td>
								</tr>
								<tr v-else>
									<td colspan="13" class="text-center">No records to display!...</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="par_cases_modal">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!-- rowdy search modal -->
	<div class="modal fade bd-example-modal-lg" id="searchModal" ref="search_modal">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="d-md-flex align-items-center justify-content-between">
					<h2 class="main-title fs-28 mt-3 ms-3">Search Rowdy</h2>
					<div class="d-md-flex align-items-center justify-content-between">
						<select class="form-select me-5" v-model="search_selection" @change="setSearchSelection()">
							<option value="">Search Selection</option>
							<option value="0">Personal Details</option>
							<option value="1">Division/Sub-Division/Police Station</option>
							<option value="2">Category</option>
							<option value="3">Cases</option>
							<option value="4">Processes</option>
							<option value="5">Goonda/Externment/KCOCA/PAR</option>
							<option value="6">IR Reports</option>
						</select>
					</div>
				</div>
				<div class="row px-4">
					<div class="ps-4">
						<div style="overflow-y:scroll; height: 60vh;">
							<div class="col-md-12" v-if="show_personal_tab">
								<label class="ms-1">
									<h5>Search by Personal Details</h5>
								</label>
								<div>
									<div class="row m-1 p-1"
										style="border: 1px; border-radius: 5px; border-color: lightgray; border-style: solid;">
										<div class="col-3 mb-2">
											<label class="form-label">Rowdy Name</label>
											<input type="text" class="form-control border-0 border-bottom"
												placeholder="Rowdy Name" v-model="meta.rowdy_sheeter_name">
										</div>
										<div class="col-3 mb-2">
											<label class="form-label">Alias</label>
											<input type="text" class="form-control border-0 border-bottom"
												placeholder="Alias" v-model="meta.alias">
										</div>
										<div class="col-3 mb-2">
											<label class="form-label">Gender</label>
											<select class="form-control border-0 border-bottom form-select"
												v-model="meta.gender">
												<option value="">Gender</option>
												<option value="Male">Male</option>
												<option value="Female">Female</option>
												<option value="Others">Others</option>
											</select>
										</div>
										<div class="col-3 mb-2">
											<label class="form-label">Age</label>
											<span class="d-flex justify-content-between">
												<input class="form-control me-1 border-0 border-bottom"
													v-model="meta.from_age" type="number" placeholder="from age">
												<input class="form-control ms-1 border-0 border-bottom"
													v-model="meta.to_age" type="number" placeholder="to age">
											</span>
										</div>
										<div class="col-3 mb-2">
											<label class="form-label">Mobile No.</label>
											<input type="text" class="form-control border-0 border-bottom"
												maxlength="10" placeholder="Mobile No." @keypress="isNumber($event)"
												v-model="meta.mobile_no">
										</div>
										<div class="col-3 mb-2">
											<label class="form-label">Locality</label>
											<input type="text" class="form-control border-0 border-bottom"
												placeholder="Rowdy locality." v-model="meta.locality">
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12" v-if="show_division_tab">
								<label class="ms-1">
									<h5>Search by Division/ Sub Division/ Police Station</h5>
								</label>
								<div>
									<div class="row m-1 p-1"
										style="border: 1px; border-radius: 5px; border-color: lightgray; border-style: solid;">
										<div class="col-4 mb-2">
											<label class="form-label">Division</label>
											<select class="form-control border-0 border-bottom form-select"
												:class="{ 'is-invalid': errors?.division_id }"
												v-model="meta.division_id" @change="getSubDivisions()">
												<option value="">Select Division</option>
												<option v-for="division, key in divisions"
													:value="division.division_id">{{
														division.division }}</option>
											</select>
											<span v-if="errors?.division_id" class="invalid-feedback">{{
												errors?.division_id[0] }}</span>
										</div>
										<div class="col-4 mb-2">
											<label class="form-label">Sub Division</label>
											<select class="form-control border-0 border-bottom form-select"
												@change="getPoliceStationByDivisions()"
												:class="{ 'is-invalid': errors?.sub_division_id }"
												v-model="meta.sub_division_id" :disabled="!meta.division_id">
												<option value="">Select Sub Division</option>
												<option v-for="sub_division, key in sub_divisions"
													:value="sub_division.sub_division_id">{{ sub_division.sub_division
													}}
												</option>
											</select>
											<span v-if="errors?.sub_division_id" class="invalid-feedback">{{
												errors?.sub_division_id[0] }}</span>
										</div>
										<div class="col-4 mb-2">
											<label class="form-label">Police Station</label>
											<search :class="{ 'is-invalid': errors?.police_station_id }"
												:customClass="{ 'is-invalid': errors?.police_station_id }"
												:initialize="police_station_id" id="police_station_id"
												label="police_station" placeholder="Select police station"
												:data="police_stations" @selected="onPoliceStationSelected"
												:disabled="!meta.sub_division_id">
											</search>
											<span v-if="errors?.police_station_id" class="invalid-feedback">{{
												errors?.police_station_id[0] }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12" v-if="show_category_tab">
								<label class="ms-1">
									<h5>Search by Category</h5>
								</label>
								<div>
									<div class="row m-1 p-1"
										style="border: 1px; border-radius: 5px; border-color: lightgray; border-style: solid;">
										<div class="col-4 mb-2">
											<label class="form-label">Category</label>
											<select class="form-control border-0 border-bottom form-select"
												v-model="meta.category" @change="getRowdyList()">
												<option value="">Select Category</option>
												<option value="A">A</option>
												<option value="B">B</option>
												<option value="C">C</option>
											</select>
										</div>
										<div class="col-4 mb-2">
											<label class="form-label">Rowdy Status</label>
											<select name="" id=""
												class="form-control border-0 border-bottom form-select"
												v-model="meta.rowdy_status" @click="getRowdyList()">
												<option value="">Select</option>
												<option value="Active">Active</option>
												<option value="Inactive">Inactive</option>
												<option value="Closed">Closed</option>
												<option value="Transfer">Transfer</option>
												<option value="Murdered">Murdered</option>
												<option value="Died">Died</option>
												<option value="Suicide">Suicide</option>
											</select>
										</div>
										<div class="col-4 mb-2">
											<label class="form-label">Where about?</label>
											<select name="" id=""
												class="form-control border-0 border-bottom form-select"
												v-model="meta.where_abouts" @change="getRowdyList()">
												<option value="">Select</option>
												<option value="Present">Present</option>
												<option value="OV">OV</option>
												<option value="JC">JC</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12" v-if="show_cases_tab">
								<label class="ms-1">
									<h5>Search by Cases</h5>
								</label>
								<div>
									<div class="row m-1 p-1"
										style="border: 1px; border-radius: 5px; border-color: lightgray; border-style: solid;">
										<div class="col-4 mb-2">
											<label class="form-label d-block">Police Station</label>
											<MultiSelect v-model="case_selected_police_stations"
												option-label="police_station" filter :options="police_stations"
												placeholder="Select Police Station" class="border-0 border-bottom"
												:maxSelectedLabels="1" :panelStyle="{ zIndex: 1500 }" appendTo="self"
												@change="CasePoliceStaionFilter(case_selected_police_stations)" />
										</div>
										<div class="col-4 mb-2">
											<label class="form-label">Crime Number</label>
											<input type="text" class="form-control border-0 border-bottom"
												v-model="meta.crime_number" placeholder="Crime Number.">
										</div>
										<div class="col-4 mb-2">
											<label class="form-label">Section of Law</label>
											<input type="text" class="form-control border-0 border-bottom"
												v-model="meta.section_of_law" placeholder="Crime Number.">
										</div>
										<div class="col-4 mb-2">
											<label class="form-label">Warrant Issued</label>
											<select class="form-control border-0 border-bottom form-select"
												v-model="meta.warrant_issued">
												<option value="">Select</option>
												<option value="Yes">Yes</option>
												<option value="No">No</option>
											</select>
										</div>
										<div class="col-4 mb-2">
											<label class="form-label">Case Status</label>
											<select class="form-control border-0 border-bottom form-select"
												v-model="meta.case_status">
												<option value="">Select</option>
												<option value="UI">UI</option>
												<option value="PT">PT</option>
												<option value="LPR">LPR</option>
												<option value="Compounded">Compounded</option>
												<option value="Acquitted">Acquitted</option>
												<option value="Convicted">Convicted</option>
											</select>
										</div>
										<div class="col-4 mb-2">
											<label class="form-label">Court Name</label>
											<input type="text" class="form-control border-0 border-bottom"
												placeholder="Court Name" v-model="meta.court_name">
										</div>
										<div class="col-4 mb-2">
											<label class="form-label">CC / SC Number</label>
											<input type="text" class="form-control border-0 border-bottom"
												v-model="meta.cc_sc_number" placeholder="CC / SC Number">
										</div>
										<div class="col-4 mb-2">
											<label class="form-label">Next Hearing date</label>
											<input type="date" class="form-control border-0 border-bottom"
												v-model="meta.next_hearing_date">
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12" v-if="show_process_tab">
								<label class="ms-1">
									<h5>Search by Processes</h5>
								</label>
								<div>
									<div class="row m-1 p-1"
										style="border: 1px; border-radius: 5px; border-color: lightgray; border-style: solid;">
										<div class="col-3 mb-2">
											<label class="form-label">Process Type</label>
											<select class="form-control border-0 border-bottom form-select"
												v-model="meta.process_type">
												<option value="">Select</option>
												<option value="warrant">Warrant</option>
												<option value="summons">Summons</option>
												<option value="notices">Notices</option>
												<option value="proclamation">Proclamation</option>
											</select>
										</div>
										<div class="col-3 mb-2">
											<label class="form-label">Police Station</label>
											<search :initialize="process_police_station_id" id="police_station_id"
												label="police_station" placeholder="Select police station"
												:data="police_stations" @selected="onProcessPoliceStationSelected">
											</search>
										</div>
										<div class="col-3 mb-2">
											<label class="form-label">Crime Number</label>
											<input type="text" class="form-control border-0 border-bottom"
												v-model="meta.process_crime_number" placeholder="Crime Number.">
										</div>
										<div class="col-3 mb-2">
											<label class="form-label">Section of Law</label>
											<input type="text" class="form-control border-0 border-bottom"
												v-model="meta.process_section_of_law" placeholder="Section of law.">
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12" v-if="show_goonda_tab">
								<label class="ms-1">
									<h5>Search by Goonda/Externment/KCOCA/PAR</h5>
								</label>
								<div class="col-4 mb-3">
									<MultiSelect v-model="selected_case_type" option-label="name" filter
										:options="tab_data" placeholder="Select Type" style="width:100%"
										@change="setCaseType()" :maxSelectedLabels="1" :panelStyle="{ zIndex: 1500 }"
										appendTo="self" />
								</div>
								<div class="row m-0 me-1">
									<div class="card my-1" v-show="goonda">
										<h5 class="text-center mt-1">Goonda Fields</h5>
										<div class="card-body">
											<div class="row">
												<div class="col-3">
													<label class="form-label">Police Station</label>
													<search :initialize="meta.goonda_police_station_id"
														id="police_station_id" label="police_station"
														placeholder="Select police station" :data="police_stations"
														@selected="police_station_id => meta.goonda_police_station_id = police_station_id">
													</search>
												</div>
												<div class="col-3">
													<label class="form-label">Order No. and Date</label>
													<input type="text" class="form-control border-0 border-bottom"
														v-model="meta.goonda_order_no"
														placeholder="Enter order no. and date..." />
												</div>
												<div class="col-3">
													<label class="form-label">Person Name</label>
													<input type="text" class="form-control border-0 border-bottom"
														v-model="meta.goonda_person_name"
														placeholder="Enter person name..." />
												</div>
												<div class="col-3">
													<label class="form-label">Approved Status</label>
													<select class="form-control border-0 border-bottom form-select"
														v-model="meta.approved_status">
														<option value="">Select</option>
														<option value="Approved">Approved</option>
														<option value="Rejected">Rejected</option>
													</select>
												</div>
											</div>
										</div>
									</div>
									<div class="card my-1" v-show="externment">
										<h5 class="text-center mt-1">Externment Fields</h5>
										<div class="card-body">
											<div class="row">
												<div class="col-3">
													<label class="form-label">Police Station</label>
													<search :initialize="meta.externment_police_station_id"
														id="police_station_id" label="police_station"
														placeholder="Select police station" :data="police_stations"
														@selected="police_station => meta.externment_police_station_id = police_station">
													</search>
												</div>
												<div class="col-3">
													<label class="form-label">Order No. and Date</label>
													<input type="text" class="form-control border-0 border-bottom"
														v-model="meta.externment_order_no"
														placeholder="Enter order no. and date..." />
												</div>
												<div class="col-3">
													<label class="form-label">Person Name</label>
													<input type="text" class="form-control border-0 border-bottom"
														v-model="meta.externment_person_name"
														placeholder="Enter person name..." />
												</div>
												<div class="col-3">
													<label class="form-label">To Which Place</label>
													<input type="text" class="form-control border-0 border-bottom"
														v-model="meta.externment_to_which_place"
														placeholder="Enter which place..." />
												</div>
											</div>
										</div>
									</div>
									<div class="card my-1" v-show="kcoca_act">
										<h5 class="text-center mt-1">KCOCA Fields</h5>
										<div class="card-body">
											<div class="row">
												<div class="col-4">
													<label class="form-label">Police Station</label>
													<search :initialize="meta.kcoca_police_station_id"
														id="police_station_id" label="police_station"
														placeholder="Select police station" :data="police_stations"
														@selected="police_station => meta.kcoca_police_station_id = police_station">
													</search>
												</div>
												<div class="col-4">
													<label class="form-label">Order No. and Date</label>
													<input type="text" class="form-control border-0 border-bottom"
														v-model="meta.kcoca_order_no"
														placeholder="Enter order no. and date..." />
												</div>
												<div class="col-4">
													<label class="form-label">CR Number</label>
													<input type="text" class="form-control border-0 border-bottom"
														v-model="meta.kcoca_cr_number"
														placeholder="Enter CR Number..." />
												</div>
											</div>
										</div>
									</div>
									<div class="card my-1" v-show="par_case">
										<h5 class="text-center mt-1">PAR Cases Fields</h5>
										<div class="card-body">
											<div class="row">
												<div class="col-4 mb-3">
													<label class="form-label" style="display: block;">Police
														Station</label>
													<MultiSelect v-model="par_selected_police_stations"
														option-label="police_station" filter :options="police_stations"
														placeholder="Select Police Station"
														class="border-0 border-bottom" :maxSelectedLabels="1"
														:panelStyle="{ zIndex: 1500 }" appendTo="self"
														@change="ParPoliceStaionFilter(par_selected_police_stations)" />
												</div>
												<div class="mb-3 col-4">
													<label class="form-label">PAR No.</label>
													<input class="form-control border-0 border-bottom"
														v-model="meta.par_no" type="text"
														placeholder="Enter PAR Number">
												</div>
												<div class="col-4 mb-3">
													<label class="form-label">Year</label>
													<input class="form-control border-0 border-bottom" type="text"
														@keypress="isNumber($event)" maxlength="4"
														v-model="meta.par_year" placeholder="Enter 4 digit year">
												</div>
												<div class="col-4 mb-3">
													<label class="form-label">Section of Law</label>
													<input type="text" class="form-control border-0 border-bottom"
														v-model="meta.par_section_of_law"
														placeholder="Enter IPC Sections..." />
												</div>
												<div class="col-4 mb-3">
													<label class="form-label">PAR Date</label>
													<input type="date" v-model="meta.par_date"
														class="form-control form-control-sm border-0 border-bottom" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12" v-if="show_ir_reports_tab">
								<label class="ms-1">
									<h5>Search IR Reports</h5>
								</label>
								<div class="card my-1">
									<h5 class="text-center mt-1">IR Report Fields</h5>
									<div class="card-body">
										<div class="row">
											<div class="col-4">
												<label class="form-label">Police Station</label>
												<search :initialize="meta.ir_police_station_id" id="police_station_id"
													label="police_station" placeholder="Select Police Station"
													:data="police_stations"
													@selected="police_station => meta.ir_police_station_id = police_station">
												</search>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button class="btn btn-primary float-end" @click="searchRowdies()">Search</button>
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="search_modal_close">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!-- Enlarge image -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="enlargeImageModal">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Rowdy Images</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="this.shift.index > 0" class="btn btn-sm btn-outline-primary float-start"
								@click="shifLeft()"> <i class="ri-arrow-left-line"></i> </button>
						</div>
						<div class="col-8">
							<img class="modal-img" :src="shift.image" alt="" />
						</div>
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="shift.index <= rowdy_images.length - 2"
								class="btn btn-sm btn-outline-primary float-end" @click="shiftRight()"> <i
									class="ri-arrow-right-line"></i> </button>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>

	<!-- IR Report download modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="ir_report_download">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">IR Report Download</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="small mt-2">
						<div class="table-responsive">
							<table class="table table-sm table-bordered table-striped">
								<thead>
									<tr class="text-center">
										<th>Sl No.</th>
										<th>Remarks</th>
										<th>IR Report File</th>
										<th>Uploaded By</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="ir_reports.length" v-for="ir_report, key in ir_reports">
										<td class="text-center">{{ key + 1 }}</td>
										<td>{{ ir_report.remarks }}</td>
										<td class="small text-center"><button
												@click="downloadIRReportFile(ir_report.ir_file)"
												class="btn btn-primary btn-sm">Download</button></td>
										<td>{{ ir_report.user?.name }} : {{ ir_report.user.role.role }}</td>
									</tr>
									<tr v-else>
										<td colspan="4" class="text-center">No records to display!...</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="process_modal_close">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
let Search = require("@/components/Search.vue").default;
import Pagination from "@/components/Pagination.vue";
import Highlighter from 'vue-highlight-words';
import MultiSelect from 'primevue/multiselect';
import moment from "moment";
import axios from "axios";

export default {
	components: {
		Pagination, Highlighter, MultiSelect, Search
	},
	name: "RowdiesCreate",
	data() {
		return {
			division_id: '',
			sub_division_id: '',
			police_station_id: '',
			case_police_station_id: '',
			process_police_station_id: '',
			goonda_police_station_id: '',
			par_police_station_id: '',
			divisions: [],
			sub_divisions: [],
			police_stations: [],
			rowdy_list: [],
			rowdies: [],
			rowdy_id: '',
			restore_page: false,
			search_selection: '',
			meta: {
				search: '',
				order_by: "asc",
				keyword: "rowdy_id",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				maxPage: 1,
				police_station_ids: [],

				//rowdy personal details
				rowdy_sheeter_name: '',
				alias: '',
				gender: '',
				mobile_no: '',
				locality: '',
				from_age: '',
				to_age: '',

				//rowdy by divsion
				division_id: '',
				sub_division_id: '',
				police_station_id: '',

				//by category
				category: '',
				rowdy_status: '',
				where_abouts: '',

				//by cases
				case_police_station_ids: [],
				crime_number: '',
				section_of_law: '',
				warrant_issued: '',
				case_status: '',
				court_name: '',
				cc_sc_number: '',
				next_hearing_date: '',

				//by Processes
				process_type: '',
				process_police_station_id: '',
				process_crime_number: '',
				process_section_of_law: '',

				case_types: [],
				//Goonda
				goonda_police_station_id: '',
				goonda_order_no: '',
				goonda_person_name: '',
				approved_status: '',
				which_place: '',//to be removed

				//externment
				externment_police_station_id: '',
				externment_order_no: '',
				externment_person_name: '',
				externment_to_which_place: '',

				//kcoca
				kcoca_police_station_id: '',
				kcoca_order_no: '',
				kcoca_cr_number: '',

				//Par Cases
				par_police_station_ids: [],
				par_no: '',
				par_year: '',
				par_section_of_law: '',
				par_date: '',

				//IR reports
				ir_police_station_id: '',
				with_ir_reports: false
				
			},
			remarks: [],
			user_remarks: '',
			moment: moment,
			edit_remarks: false,
			user_remark_id: '',
			scroll_top: 0,
			show: false,
			ipc_cases: [],
			search_police_station: '',
			rowdy_image: '',
			rowdy_images: [],
			shift: {
				index: 0,
				image: ''
			},
			search_police_station: '',
			selected_police_stations: [],
			case_selected_police_stations: [],
			par_selected_police_stations: [],
			pending_processes: [],

			//Search selection
			show_personal_tab: false,
			show_division_tab: false,
			show_category_tab: false,
			show_cases_tab: false,
			show_process_tab: false,
			show_goonda_tab: false,
			show_ir_reports_tab: false,

			//Goonda selection
			goonda: false,
			externment: false,
			kcoca_act: false,
			par_case: false,

			//searched fields
			personal_fields: [],
			division_fields: [],
			category_fields: [],
			cases_fields: [],
			processes_fields: [],
			goonda_act_fields: [],
			externment_act_fields: [],
			kcoca_act_fields: [],
			par_cases_fields: [],
			ir_reports_fields: [],

			goondas: [],
			par_cases: [],
			ir_reports: [],

			//goonda, externment etc...
			tab_data: [
				{
					name: 'Goonda',
					value: 'Goonda'
				},
				{
					name: 'Externment',
					value: 'Externment'
				},
				{
					name: 'KCOCA Act',
					value: 'kcoca_act'
				},
				{
					name: 'PAR Cases',
					value: 'par_cases'
				}
			],
			selected_case_type: [],
			rowdy_sheets: 0,
			police_station_users: []
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.getPoliceStations();
			vm.getDivisions();
		});
	},

	mounted() {
		if (sessionStorage.getItem('rowdy_meta') && this.restore_page) {
			this.meta = JSON.parse(sessionStorage.getItem('rowdy_meta'));
			sessionStorage.removeItem('rowdy_meta');
		}
		if (sessionStorage.getItem('rowdy_list') && this.restore_page) {
			this.rowdy_list = JSON.parse(sessionStorage.getItem('rowdy_list'));
			sessionStorage.removeItem('rowdy_list');
		}
		if (sessionStorage.getItem('scroll_top') && this.restore_page) {
			this.scroll_top = sessionStorage.getItem('scroll_top');
			sessionStorage.removeItem('scroll_top');
		}
		this.getRowdyList();
	},

	unmounted() {
		sessionStorage.setItem('rowdy_meta', JSON.stringify(this.meta));
		sessionStorage.setItem('rowdy_list', JSON.stringify(this.rowdy_list));
		sessionStorage.setItem('scroll_top', this.scroll_top);
	},

	computed: {
		rowdy_sheeter_name() {
			return this.meta.rowdy_sheeter_name.split(' ')
		},

		alias() {
			return this.meta.alias.split(' ')
		},
	},
	methods: {
		getRowdyList() {
			let vm = this;
			let uri = { uri: "searchRowdies", data: vm.meta };
			vm.$store.dispatch("post", uri)
				.then(function (response) {
					if (!vm.restore_page) {
						vm.rowdy_list = response.data.data;
					}
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
					//searched fields
					vm.personal_fields = response.data.search_fields.personal_fields;
					vm.division_fields = response.data.search_fields.division_fields;
					vm.category_fields = response.data.search_fields.category_fields;
					vm.cases_fields = response.data.search_fields.cases_fields;
					vm.processes_fields = response.data.search_fields.processes_fields;
					vm.goonda_act_fields = response.data.search_fields.goonda_act_fields;
					vm.externment_act_fields = response.data.search_fields.externment_act_fields;
					vm.kcoca_act_fields = response.data.search_fields.kcoca_act_fields;
					vm.par_cases_fields = response.data.search_fields.par_cases_fields;
					vm.ir_reports_fields = response.data.search_fields.ir_reports_fields;
					if (vm.restore_page) {
						vm.$refs.rowdy_scroll.scrollTop = vm.scroll_top;
						vm.restore_page = false;
					}
					vm.rowdy_sheets = response.data.rowdy_sheets;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},

		deleteRowdy(rowdy_id) {
			if (confirm("Are you sure you want to delete")) {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = { uri: 'deleteRowdy', data: { 'rowdy_id': rowdy_id } };
				vm.$store.dispatch("post", uri)
					.then(function (response) {
						loader.hide();
						vm.$store.dispatch("success", response.data.message);
						vm.getRowdyList();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},

		onPageChange(page) {
			this.meta.page = page;
			this.getRowdyList();
		},

		sort(field) {
			this.meta.keyword = field;
			this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
			this.getRowdyList();
		},
		search() {
			this.rowdy_list = [];
			this.meta.page = 1;
			this.getRowdyList();
		},

		policeStationCategories(categories) {
			let station_category = [];
			if (categories.length) {
				categories.forEach(ele => {
					// Determine the background color based on rowdy_status
					let bgColor = "";
					if (ele.rowdy_status === "Active") {
						bgColor = "green";
					} else if (ele.rowdy_status === "Inactive") {
						bgColor = "orange";
					} else if (ele.rowdy_status === "Closed") {
						bgColor = "red";
					} else {
						bgColor = "gray";
					}

					// Create an HTML string with styled content
					let str = `
						<span style="background-color: ${bgColor}; padding: 1px; border-radius: 3px; color:#fff">
							${ele.category} - ${ele.police_station.police_station} - ${ele.rowdy_status}
						</span>`;
					station_category.push(str);
				});
			}
			station_category.sort();
			return station_category.join(",<br style='margin-bottom:10px'>");
		},

		onScroll(e) {
			const scroll = e.target.offsetHeight + e.target.scrollTop;
			const height = e.target.scrollHeight - 1;
			this.scroll_top = e.target.scrollTop;
		},

		getUserRemarks(rowdy_id) {
			this.rowdy_id = rowdy_id;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getUserRemarks', data: { rowdy_id: this.rowdy_id } })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		addUserRemarks() {
			if (this.user_remarks == '') {
				alert('Remarks field cannot be empty!');
				return;
			}
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'addUserRemarks', data: { rowdy_id: this.rowdy_id, remarks: this.user_remarks } })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		deleteUserRemarks(user_remark_id) {
			if (!confirm('This remark will be deleted permanantly!')) {
				return;
			}
			let vm = this;
			let loader = this.$loading.show();
			let data = { rowdy_id: this.rowdy_id, user_remark_id: user_remark_id };
			this.$store.dispatch('post', { uri: 'deleteUserRemarks', data: data })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
				})
				.catch(error => {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		editRemarks(remark) {
			this.edit_remarks = true;
			this.user_remarks = remark.remarks;
			this.user_remark_id = remark.user_remark_id;
		},

		updateUserRemarks() {
			this.edit_remarks = false;
			let vm = this;
			let data = { rowdy_id: this.rowdy_id, remarks: this.user_remarks, user_remark_id: this.user_remark_id }
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'updateUserRemarks', data: data })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		discardRemarks() {
			this.user_remarks = '';
		},

		getPoliceStations() {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getPoliceStations' })
				.then(response => {
					loader.hide();
					this.police_stations = response.data.data;
					this.police_stations.sort();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		onPoliceStationSelected(police_station_id) {
			this.meta.police_station_id = police_station_id;
			this.police_station_id = police_station_id;
			this.rowdy_list = [];
			this.restore_page = false;
			this.getRowdyList();
		},

		onProcessPoliceStationSelected(police_station_id) {
			this.meta.process_police_station_id = police_station_id;
			this.process_police_station_id = police_station_id;
			this.rowdy_list = [];
			this.restore_page = false;
			this.getRowdyList();
		},

		clearSelection() {
			this.meta.police_station_ids = [];
			this.selected_police_stations = [];
			this.par_police_station_ids = [];
			this.meta.search = '';
			this.meta.page = 1;
			this.search_selection = '';

			//personal details
			this.meta.rowdy_sheeter_name = '';
			this.meta.alias = '';
			this.meta.gender = '';
			this.meta.mobile_no = '';
			this.meta.locality = '';
			this.meta.from_age = '';
			this.meta.to_age = '';
			//Division
			this.meta.division_id = '';
			this.meta.sub_division_id = '';
			this.meta.police_station_id = '';
			//category
			this.meta.category = '';
			this.meta.rowdy_status = '';
			this.meta.where_abouts = '';
			//cases
			this.case_police_station_id = '';
			this.case_selected_police_stations = [];
			this.meta.case_police_station_ids = [];
			this.meta.crime_number = '';
			this.meta.section_of_law = '';
			this.meta.warrant_issued = '';
			this.meta.case_status = '';
			this.meta.court_name = '';
			this.meta.cc_sc_number = '';
			this.meta.next_hearing_date = '';
			// processes
			this.meta.process_type = '';
			this.meta.process_police_station_id = '';
			this.process_police_station_id = '';
			this.meta.process_crime_number = '';
			this.meta.process_section_of_law = '';

			this.meta.case_types = [],
			this.selected_case_type = [],
			// Goonda
			this.meta.goonda_police_station_id = '';
			this.goonda_police_station_id = '';
			this.meta.goonda_person_name = '';
			this.meta.approved_status = '';
			this.meta.goonda_order_no = '';
			// externment			
			this.meta.externment_police_station_id = '';
			this.meta.externment_order_no = '';
			this.meta.externment_person_name = '';
			this.meta.externment_to_which_place = '';
			// kcoca
			this.meta.kcoca_police_station_id = '';
			this.meta.kcoca_order_no = '';
			this.meta.kcoca_cr_number = '';
			//Par cases
			this.meta.par_no = '';
			this.meta.par_year = '';
			this.meta.par_police_station_ids = [];
			this.par_selected_police_stations = [];
			this.meta.par_section_of_law = '';
			this.meta.par_date = '';
			//IR Reports
			this.meta.ir_police_station_id = '';
			this.meta.with_ir_reports = false;


			this.getRowdyList();
			this.setCaseType()
		},

		getIpcCases(rowdy_id, case_type) {
			let vm = this;
			this.ipc = case_type;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getIpcCases', data: { rowdy_id: rowdy_id, case_type: case_type } })
				.then(response => {
					loader.hide();
					this.ipc_cases = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		editIpcCase(rowdy_id, rowdies_case_id) {
			this.$refs.ipc_modal_close.click();
			this.$router.push('/rowdy_case_update/' + rowdy_id + '/' + rowdies_case_id);
		},

		filterPoliceStation() {
			this.police_stations.forEach(ele => { ele.v_flag = true; });
			if (this.search_police_station == '') {
				return;
			}
			this.police_stations.forEach(ele => {
				if (!ele.police_station.toLowerCase().includes(this.search_police_station.toLowerCase())) {
					ele.v_flag = false;
				};
			})
		},

		getRowdyImages(rowdy_id) {
			let vm = this;
			let loader = vm.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdyImages', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.rowdy_images = response.data.data;
					if (this.rowdy_images.length) {
						this.shift.index = 0;
						this.shift.image = this.rowdy_images[0].image;
					}
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		shifLeft() {
			if (this.rowdy_images.length) {
				this.shift.index--;
				this.shift.image = this.rowdy_images[this.shift.index].image;
			}
		},

		shiftRight() {
			if (this.rowdy_images.length) {
				this.shift.index++;
				this.shift.image = this.rowdy_images[this.shift.index].image;
			}
		},


		PoliceStaionFilter(selected_police_stations) {
			let vm = this;
			vm.meta.police_station_ids = []
			selected_police_stations.map(function (ele) {
				vm.meta.police_station_ids.push(ele.police_station_id)
			})
			// vm.rowdy_list = [];
			vm.getRowdyList();
		},
		CasePoliceStaionFilter(selected_police_stations) {
			let vm = this;
			vm.meta.case_police_station_ids = []
			selected_police_stations.map(function (ele) {
				vm.meta.case_police_station_ids.push(ele.police_station_id)
			})
			vm.getRowdyList();
		},

		ParPoliceStaionFilter(selected_police_stations) {
			let vm = this;
			vm.meta.par_police_station_ids = []
			selected_police_stations.map(function (ele) {
				vm.meta.par_police_station_ids.push(ele.police_station_id)
			})
			vm.getRowdyList();
		},

		filterByCase() {
			this.rowdy_list = [];
			this.meta.page = 1;
			this.getRowdyList();
		},

		isNumber(e) {
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'Enter'];
			const keyPressed = e.key;
			if (!keysAllowed.includes(keyPressed)) {
				e.preventDefault()
			}
		},

		getPendingProcesses(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getPendingProcesses', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.pending_processes = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		editProcess(process_id, rowdy_id) {
			this.$refs.ipc_modal_close.click();
			this.$router.push('/rowdy_process_update/' + rowdy_id + '/' + process_id);
		},

		getDivisions() {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getDivisions' })
				.then(response => {
					loader.hide();
					this.divisions = response.data.data;
					this.getRowdyList();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getSubDivisions() {
			this.meta.sub_division_id = '';
			this.meta.police_station_id = '';
			this.police_station_id = '';
			this.restore_page = false;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getSubDivisions', data: { division_id: this.meta.division_id } })
				.then(response => {
					loader.hide();
					this.sub_divisions = response.data.data;
					this.getRowdyList();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getPoliceStationByDivisions() {
			this.meta.police_station_id = '';
			if (this.meta.sub_division_id == '') {
				this.getRowdyList();
				return;
			}
			let vm = this;
			let data = {
				division_id: this.meta.division_id,
				sub_division_id: this.meta.sub_division_id
			}
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getPoliceStationByDivisions', data: data })
				.then(response => {
					loader.hide();
					this.police_stations = response.data.data;
					this.getRowdyList();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		onPoliceStationSelect(police_station_id) {
			this.police_station_id = police_station_id;
			this.getRowdyList();
		},

		setSearchSelection() {
			this.show_personal_tab = false;
			this.show_division_tab = false;
			this.show_category_tab = false;
			this.show_cases_tab = false;
			this.show_process_tab = false;
			this.show_goonda_tab = false;
			this.show_ir_reports_tab = false;
			switch (this.search_selection) {
				case '0':
					this.show_personal_tab = true;
					break;
				case '1':
					this.show_division_tab = true;
					break;
				case '2':
					this.show_category_tab = true;
					break;
				case '3':
					this.show_cases_tab = true;
					break;
				case '4':
					this.show_process_tab = true;
					break;
				case '5':
					this.show_goonda_tab = true;
					break;
				case '6':
					this.show_ir_reports_tab = true;
					this.meta.with_ir_reports = true;
					break;
				default:
					break;
			}
		},

		searchRowdies() {
			this.meta.page = 1;
			this.$refs.search_modal_close.click();
			this.getRowdyList();
		},

		setCaseType() {
			this.goonda = false;
			this.externment = false;
			this.kcoca_act = false;
			this.par_case = false;
			this.meta.case_types = [];
			this.selected_case_type.forEach(ele => {
				if (ele.name == 'Goonda') {
					this.goonda = true;
					this.meta.case_types.push('Goonda');
				}
				if (ele.name == 'Externment') {
					this.externment = true;
					this.meta.case_types.push('Externment');
				}
				if (ele.name == 'KCOCA Act') {
					this.kcoca_act = true;
					this.meta.case_types.push('KCOCA_Act');
				}
				if (ele.name == 'PAR Cases') {
					this.par_case = true;
				}
			});

		},

		getGoondaExternmentKcoca(rowdy_id, case_type) {
			let vm = this;
			this.goonda_title = case_type;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getGoondaExternmentKcoca', data: { rowdy_id: rowdy_id, case_type: case_type } })
				.then(response => {
					loader.hide();
					this.goondas = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getParCases(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show()
			this.$store.dispatch('post', { uri: 'getParCases', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide()
					this.par_cases = response.data.data
				})
				.catch(error => {
					loader.hide()
					vm.errors = error.response.errors
					vm.$store.dispatch('error', error.response.data.message)
				})
		},

		editParCase(rowdy_id, par_case_id) {
			this.$refs.par_cases_modal.click()
			this.$router.push('/rowdy_par_update/' + rowdy_id + '/' + par_case_id)
		},

		editGoondaCase(rowdy_id, goonda_id) {
			this.$refs.goonda_modal_close.click()
			this.$router.push('/rowdy_goonda_update/' + rowdy_id + '/' + goonda_id)
		},

		downloadSearchRowdies() {
			let police_station_ids = JSON.stringify(this.meta.police_station_ids);
			let loader = this.$loading.show();
			const downloadExcelWithAuth = async () => {
				try {
					// axios.defaults.withCredentials = true;
					const response = await axios.post(this.$store.getters.downloadUrl + 'api/exportSearchRowdies',
						{
							// rowdy personal details
							rowdy_sheeter_name: this.meta.rowdy_sheeter_name,
							alias: this.meta.alias,
							gender: this.meta.gender,
							mobile_no: this.meta.mobile_no,
							locality: this.meta.locality,

							// divsions
							division_id: this.meta.division_id,
							sub_division_id: this.meta.sub_division_id,
							police_station_ids: police_station_ids,
							police_station_id: this.meta.police_station_id,

							// category
							category: this.meta.category,
							rowdy_status: this.meta.rowdy_status,
							where_abouts: this.meta.where_abouts,

							// rowdies cases
							case_police_station_ids: this.meta.case_police_station_ids,
							crime_number: this.meta.crime_number,
							section_of_law: this.meta.section_of_law,
							warrant_issued: this.meta.warrant_issued,
							case_status: this.meta.case_status,
							court_name: this.meta.court_name,
							cc_sc_number: this.meta.cc_sc_number,
							next_hearing_date: this.meta.next_hearing_date,

							// process
							process_type: this.meta.process_type,
							process_police_station_id: this.meta.process_police_station_id,
							process_crime_number: this.meta.process_crime_number,
							process_section_of_law: this.meta.process_section_of_law,

							// goonda act
							goonda_police_station_id: this.meta.goonda_police_station_id,
							goonda_person_name: this.meta.goonda_person_name,
							goonda_order_no: this.meta.goonda_order_no,
							approved_status: this.meta.approved_status,

							// externment							
							externment_police_station_id: this.meta.externment_police_station_id,
							externment_order_no: this.meta.externment_order_no,
							externment_person_name: this.externment_person_name,
							which_place: this.meta.which_place,

							// kcoca
							kcoca_police_station_id: this.meta.kcoca_police_station_id,
							kcoca_order_no: this.meta.kcoca_order_no,
							kcoca_cr_number: this.meta.kcoca_cr_number,

							// par cases
							par_police_station_ids: this.meta.par_police_station_ids,
							par_no: this.meta.par_no,
							par_year: this.meta.par_year,
							par_section_of_law: this.meta.par_section_of_law,
							par_date: this.meta.par_date,
						},
						{
							responseType: 'blob',
							headers: {
								Authorization: 'Bearer ' + this.$store.getters.token
							}
						}
					);
					const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = 'export_rowdies.xlsx';
					link.click();
					URL.revokeObjectURL(link.href);
					loader.hide();
				} catch (error) {
					loader.hide();
					console.error('Error downloading the excel:', error);
					alert('There was an issue downloading the excel.');
				}
			};
			downloadExcelWithAuth();
		},

		getIRReports(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getIRReports', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.ir_reports = response.data.data;
				})
				.catch(error => {
					loader.hide()
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		downloadIRReportFile(filename) {
			let loader = this.$loading.show();
			const downloadPDFWithAuth = async () => {
				try {
					// axios.defaults.withCredentials = true;
					const response = await axios.post(this.$store.getters.downloadUrl + 'api/downloadIRReportFile',
						{
							filename: filename
						},
						{
							responseType: 'blob',
							headers: {
								Authorization: 'Bearer ' + this.$store.getters.token
							}
						}
					);
					loader.hide();
					let mimeType;
					let fileType = filename.split('.').pop().toLowerCase();
					switch (fileType) {
						case 'pdf':
							mimeType = 'application/pdf';
							break;
						case 'doc':
							mimeType = 'application/msword';
							break;
						case 'docx':
							mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
							break;
						default:
							mimeType = 'application/octet-stream';
							break;
					}
					const blob = new Blob([response.data], { type: mimeType });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = `ir_report_${Date.now()}.${fileType}`;
					link.click();
					URL.revokeObjectURL(link.href);
				} catch (error) {
					loader.hide();
					console.error('Error downloading the file:', error);
					alert('There was an issue downloading the file.');
				}
			};
			downloadPDFWithAuth();
		},
	}
}
</script>
<style scoped>
.card-one {
	border-width: 0px;
}

@media only screen and (max-width: 767px) {
	.gap-2 {
		margin-bottom: 0.5rem;
	}
}

.dropdown {
	position: relative;
	cursor: pointer;
}

.multiselect {
	position: relative;
}

.multiselect ul {
	border: 1px solid #ddd;
	border-top: 0;
	border-radius: 0 0 3px 3px;
	left: 0px;
	padding: 8px 8px;
	top: -0.1rem;
	width: 100%;
	list-style: none;
	max-height: 150px;
	overflow: auto;
	background: white;
}

.overselect {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1500;
}

.multiselect {
	position: relative;
}

thead {
	/* position: relative !important; */
	top: -2px !important;
	z-index: 5 !important;
	background-color: #f3f5f9
}
</style>
