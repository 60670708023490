import { createStore } from 'vuex'
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import axios from "axios";



export default createStore({
	state: {
		apiUrl: "https://rs.patterneffects.in/api/",
		download_url: "https://rs.patterneffects.in/",

		// apiUrl: "http://localhost:8000/api/",
		// download_url: "http://localhost:8000/",

		// apiUrl: "http://localhost/rowdy-sheeter-banglore/laravel/public/api/",
		// download_url: "http://localhost/rowdy-sheeter-banglore/laravel/public/",
		user: null,
		token: "",
		permissions: []
	},
	getters: {
		user(state) {
			return state.user;
		},
		token(state) {
			return state.token;
		},
		downloadUrl(state) {
			return state.download_url;
		},
		permissions(state) {
			return state.permissions;
		},
	},
	mutations: {
		setUser(state, user) {
			state.user = user;
		},
		setToken(state, token) {
			state.token = token;
		},
		setPermissions(state, permissions) {
			state.permissions = permissions;
		},
	},
	actions: {
		async setUser(context, payload) {
			sessionStorage.setItem("user", JSON.stringify(payload))
			context.commit('setUser', payload);
		},
		async setToken(context, payload) {
			sessionStorage.setItem("token", payload)
			context.commit('setToken', payload);
		},
		async logout(context) {
			sessionStorage.removeItem('user');
			sessionStorage.removeItem('token');
			sessionStorage.removeItem('permissions');
			context.commit('setUser', null);
			context.commit('setToken', "");
			context.commit('setPermissions', null);
		},

		auth(context, payload) {
			return new Promise((resolve, reject) => {
				// axios.defaults.withCredentials = true;
				axios.post(this.state.apiUrl + payload.uri, payload.data)
					.then(function (response) {
						resolve(response);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		},

		post(context, payload) {
			return new Promise((resolve, reject) => {
				// axios.defaults.withCredentials = true;
				axios.post(this.state.apiUrl + payload.uri, payload.data, {
					headers: {
						'Authorization': 'Bearer' + ' ' + context.getters.token
					}
				}).then(function (response) {
					resolve(response);
				})
					.catch(function (error) {
						reject(error);
						if (error.response.data.message == "Unauthenticated.") {
							context.dispatch('logout');
							window.location.href = "/#/login"
							window.location.reload();
						}
					});
			});
		},

		async error(context, description) {
			await createToast(
				{
					title: "Error",
					description: description || "The given data was invalid.",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "danger",
					position: "top-right",
				}
			);
		},

		async success(context, description) {
			await createToast(
				{
					title: "Success",
					description: description || "Data is successfully submitted.",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "success",
					position: "top-right",
				}
			);
		},

		async info(context, description) {
			await createToast(
				{
					title: "Info",
					description: description || "Not enough input data",
				},
				{
					showIcon: true,
					hideProgressBar: true,
					type: "info",
					position: "top-right",
				}
			);
		},
		async setPermissions(context, payload) {
			await context.commit('setPermissions', payload);
		},
	}
})
