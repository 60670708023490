<template>
	<div class="d-md-flex align-items-center justify-content-between1 gap-2 mb-2">
		<!-- <h4 class="main-title mb-0">Rowdies List</h4> -->
		<div class="form-group align-items-center gap-2 ml-auto">
			<div class="d-flex justify-content-between">
				<label class="form-label small font-gray">
					Division
				</label>
				<label>
					<a href="javascrip:void(0)" class="fw-500 small text-danger" @click="clearSelection()">Reset</a>
				</label>
			</div>
			<select class="form-select" :class="{ 'is-invalid': errors?.division_id }" v-model="meta.division_id"
				@change="getSubDivisions()">
				<option value="">Select Division</option>
				<option v-for="division, key in divisions" :value="division.division_id">{{
					division.division }}</option>
			</select>
			<span v-if="errors?.division_id" class="invalid-feedback">{{
				errors?.division_id[0] }}</span>
		</div>
		<div class="align-items-center gap-2">
			<div class="d-flex justify-content-between">
				<label class="form-label small font-gray"> 
					Sub Division
				</label>
				<label>
					<a href="javascrip:void(0)" class="fw-500 small text-danger" @click="clearSelection()">Reset</a>
				</label>
			</div>
			<select class="form-select" @change="getPoliceStationByDivisions()"
				:class="{ 'is-invalid': errors?.sub_division_id }" v-model="meta.sub_division_id"
				:disabled="!meta.division_id">
				<option value="">Select Sub Division</option>
				<option v-for="sub_division, key in sub_divisions" :value="sub_division.sub_division_id">{{
					sub_division.sub_division
				}}
				</option>
			</select>
			<span v-if="errors?.division_id" class="invalid-feedback">{{
				errors?.division_id[0] }}</span>
		</div>
		<div class="align-items-center gap-2">
			<div class="d-flex justify-content-between">
				<label class="form-label small font-gray">
					Police Station
					<span class="fw-bold" style="color: green;">[ {{ meta.police_station_ids.length }} ]</span>
				</label>
				<label>
					<a href="javascrip:void(0)" class="fw-500 small text-danger" @click="clearSelection()">Reset</a>
				</label>
			</div>
			<MultiSelect v-model="selected_police_stations" filter optionLabel="police_station"
				:options="police_stations" placeholder="Select Police Station" :maxSelectedLabels="1" class="w-250"
				style="height: 35px;" @change="PoliceStaionFilter(selected_police_stations)" />
		</div>
		<div class="align-items-center gap-2">
			<div class="d-flex justify-content-between">
				<label class="form-label small font-gray">
					Rowdy Status
				</label>
				<label>
					<a href="javascrip:void(0)" class="fw-500 small text-danger" @click="clearSelection()">Reset</a>
				</label>
			</div>
			<MultiSelect v-model="meta.rowdy_status" filter :options="rowdy_status" placeholder="Select Status"
				:maxSelectedLabels="1" @change="search()" />
		</div>
		<div class="align-items-center gap-2">
			<div class="d-flex justify-content-between">
				<label class="form-label small font-gray">
					Case Type
				</label>
				<label>
					<a href="javascrip:void(0)" class="fw-500 small text-danger" @click="clearSelection()">Reset</a>
				</label>
			</div>
			<MultiSelect v-model="meta.case_types" filter :options="case_types" placeholder="Case Type"
				:maxSelectedLabels="1" @change="search()" />
		</div>
		<div class="align-items-center gap-2">
			<label class="form-label small font-gray">
				Search Name, Mobile No, Voter No, Coid No, Aadhaar
			</label>
			<input type="text" class="form-control" placeholder="Search" v-model="meta.search"
				@keypress.enter="search()" />
		</div>
		<!-- <button type="button" class="mt-auto btn btn-outline-primary d-flex align-items-center gap-2"
			@click="addRowdy()"><i class="ri-add-line fs-18 lh-1"></i>Add New Rowdy
		</button> -->
	</div>
	<div class="row g-3">
		<div class="col-xl-12">
			<div class="card card-one">
				<div class="card-header d-flex justify-content-between">
					<h6 class="text-primary pt-2"> Rowdy Sheeters : {{ meta.totalRows }}, Rowdy Sheets : {{ rowdy_sheets
					}}</h6>
					<div class="small">
						<label class="form-label mx-2" style="display: inline;">Filter by total no. of Cases</label>
						<input type="text" v-model="meta.case_count.from" @keydown="isNumber($event)"
							@keypress.enter="filterByCase()" style="width: 100px; display: inline;"
							class="form-control form-control-sm" placeholder="From">
						<input type="text" v-model="meta.case_count.to" @keydown="isNumber($event)"
							@keypress.enter="filterByCase()" style="width: 100px; display: inline;"
							class="form-control form-control-sm mx-2" placeholder="To">
						<!-- <button v-can="'rowdy.export_pdf'" class="btn btn-sm btn-success mx-2"
							@click="downloadRowdiesPdf()">Export
							(pdf)</button> -->
						<!-- <button v-can="'rowdy.export_excel'" class="btn btn-sm btn-success"
							@click="downloadRowdies()">Export 
							(excel)</button> -->
						<button v-can="'rowdy.export_excel'" class="btn btn-sm btn-success ms-2"
							@click="downloadRowdiesCases()">Export (excel)</button>
						<button type="button" class="mt-auto btn btn-primary btn-sm mx-2" @click="addRowdy()">New
							Rowdy
						</button>
					</div>
				</div>
				<div class="card-body">
					<div class="table-responsive" ref="rowdy_scroll" style="overflow-y:scroll; height: 65vh;"
						@scroll="onScroll($event)">
						<table class="table table-hover table-sm table-bordered table-striped mb-0">
							<thead class="freeze-head text-center">
								<tr style="background-color: #f5f5f5;">
									<th class="text-center" rowspan="2" v-can="'rowdy.update'">
										Edit<br>
										<div class="form-check">
											<input class="form-check-input" type="checkbox" v-model="meta.with_remarks"
												@change="search()" />
											<label class="form-check-label small text-success" for="">Remarks</label>
										</div>
									</th>
									<th class="text-center" rowspan="2">Sl No.</th>
									<th class="text-center" rowspan="2">Photo</th>
									<th @click="sort('rowdy_sheeter_name')" rowspan="2">Rowdy Names
										<span>
											<i v-if="meta.keyword == 'rowdy_sheeter_name' && meta.order_by == 'asc'"
												class="ri-sort-alphabet-asc"></i>
											<i v-else-if="meta.keyword == 'rowdy_sheeter_name' && meta.order_by == 'desc'"
												class="ri-sort-alphabet-desc"></i>
											<i v-else class="ri-arrow-up-down-line"></i>
										</span>
									</th>
									<th rowspan="2">Alias</th>
									<th @click="sort('father_name')" rowspan="2">Father Name
										<span>
											<i v-if="meta.keyword == 'father_name' && meta.order_by == 'asc'"
												class="ri-sort-alphabet-asc"></i>
											<i v-else-if="meta.keyword == 'father_name' && meta.order_by == 'desc'"
												class="ri-sort-alphabet-desc"></i>
											<i v-else class="ri-arrow-up-down-line"></i>
										</span>
									</th>
									<th rowspan="2" class="th-lg">Rowdy Age</th>
									<th rowspan="2" class="th-lg">Gender</th>
									<th rowspan="2" class="th-lg">Locality</th>
									<th rowspan="2" class="th-lg text-center">
										Category<br>Police Station<br>
										<span class="d-flex justify-content-between">
											<div class="form-check form-check-inline small">
												<input class="form-check-input" type="checkbox" @change="search()" id="inlineCheckbox1" v-model="meta.opened_order_only">
												<label class="form-check-label text-success"
													for="inlineCheckbox1">Opened</label>
											</div>
											<div class="form-check form-check-inline small">
												<input class="form-check-input" type="checkbox" @change="search()" id="inlineCheckbox2" v-model="meta.closed_order_only">
												<label class="form-check-label text-success"
													for="inlineCheckbox2">Closed</label>
											</div>
										</span>
									</th>
									<th rowspan="2" class="th-lg text-center">
										Created<br>Police Station</th>
									<th rowspan="2">Mobile</th>
									<th rowspan="2">Aadhaar</th>
									<th rowspan="2">
										IR Reports
										<div class="form-check small text-success">
											<input class="form-check-input" type="checkbox"
												v-model="meta.with_ir_reports" @change="search()" />
											<label class="form-check-label" for=""> Show Only IR </label>
										</div>
									</th>
									<th style="vertical-align: bottom !important" class="text-center" colspan="4">
										No. Of Cases
									</th>
									<th rowspan="2" class="text-center">
										Pending <br> Warrants/ <br>Proclamations
									</th>
									<th style="vertical-align: bottom !important" class="text-center" colspan="3">
										Goonda Act / Externment / KCOCA Act
									</th>
									<th rowspan="2">PAR<br>Cases</th>
									<th rowspan="2">Created By PS</th>
									<th class="text-center" rowspan="2" v-can="'rowdy.delete'">
										Delete
									</th>
								</tr>
								<tr style="background-color: #f5f5f5;" class="text-center">
									<th style="vertical-align: top !important">MURDER</th>
									<th style="vertical-align: top !important">ATTEMPT<br>TO MURDER</th>
									<th style="vertical-align: top !important">Other <br> Cases</th>
									<th style="vertical-align: top !important">Total</th>
									<th style="vertical-align: top !important">Goonda<br>Act</th>
									<th style="vertical-align: top !important">Externment</th>
									<th style="vertical-align: top !important">KCOCA<br>Act</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="rowdy, key in rowdy_list" :key="key">
									<tr>
										<td class="text-center" v-can="'rowdy.update'">
											<router-link :to="'/update_cases_processes/' + rowdy.rowdy_id"
												href="javascript:void(0)" class="text-primary me-2" title="Edit Rowdy">
												Edit
											</router-link>

											<a href="javascript:void(0)" class="text-success me-2"
												v-can="'rowdy.remarks'" title="User Remarks" data-bs-toggle="modal"
												data-bs-target="#viewRemarks" @click="getUserRemarks(rowdy.rowdy_id)">
												Remark
											</a><br>
											<span class="text-danger">{{ rowdy.remarks_count }}</span>
										</td>
										<td class="text-center">{{ meta.from + key }}</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#enlargeImageModal"
												@click="getRowdyImages(rowdy.rowdy_id)">
												<img :src="rowdy.rowdy_sheeter_photo" alt="" width="60px">
											</a>
										</td>
										<td style="text-transform: uppercase;">
											<router-link :to="'/view_rowdy/' + rowdy.rowdy_id"
												class="text-primary me-2">
												<Highlighter class="my-highlight" highlightClassName="highlight"
													:searchWords="keywords" :autoEscape="true"
													:textToHighlight="rowdy.rowdy_sheeter_name" />
											</router-link>
										</td>
										<td style="text-transform: uppercase;">
											<Highlighter class="my-highlight" highlightClassName="highlight"
												:searchWords="keywords" :autoEscape="true"
												:textToHighlight="rowdy.alias" />
										</td>
										<td>{{ rowdy.father_name }}</td>
										<td class="text-center">{{ rowdy.age }}</td>
										<td class="text-center">{{ rowdy.gender }}</td>
										<td class="text-center text-nowrap">{{ rowdy.locality }}</td>
										<td class="small text-center text-nowrap text-uppercase">
											<span class="d-flex justify-content-between align-items-center">
												<span v-html="policeStationCategories(rowdy.rowdy_categories)"></span>
												<a href="javascript:void(0)" data-bs-toggle="modal"
													v-if="rowdy?.opened_closed_document_exists"
													@click="setOpenedClosedModal(rowdy.rowdy_categories)"
													class="btn btn-sm btn-outline-success ms-2"
													data-bs-target="#open_closed_order_download">
													<i class="ri-download-line"></i>
												</a>
											</span>
										</td>
										<td class="small text-center text-nowrap text-uppercase">
											{{ rowdy?.user?.police_station }}
										</td>
										<td>{{ rowdy.mobile_no }}</td>
										<td>{{ rowdy.aadhaar_number }}</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												v-if="rowdy.ir_reports_count" @click="getIRReports(rowdy.rowdy_id)"
												class="btn btn-sm btn-outline-success"
												data-bs-target="#ir_report_download">
												<i class="ri-download-line"></i>
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#ipcModal"
												@click="getIpcCases(rowdy.rowdy_id, 'MURDER')">
												{{ rowdy.count_murder_cases }}
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#ipcModal"
												@click="getIpcCases(rowdy.rowdy_id, 'ATTEMPT_TO_MURDER')">
												{{ rowdy.count_attempt_to_murder_cases }}
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#ipcModal"
												@click="getIpcCases(rowdy.rowdy_id, 'Others')">
												{{ rowdy.no_of_cases - (rowdy.count_murder_cases +
													rowdy.count_attempt_to_murder_cases) }}
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#ipcModal"
												@click="getIpcCases(rowdy.rowdy_id, 'total')">
												{{ rowdy.no_of_cases }}
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#pendingProcesses"
												@click="getPendingProcesses(rowdy.rowdy_id)">
												{{ rowdy.process_pending_count }}
											</a>
										</td>

										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#goonda_externment_kcoca_modal"
												@click="getGoondaExternmentKcoca(rowdy.rowdy_id, 'Goonda Act')">
												{{ rowdy.goonda_count }}
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#goonda_externment_kcoca_modal"
												@click="getGoondaExternmentKcoca(rowdy.rowdy_id, 'Externment')">
												{{ rowdy.externment_count }}
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#goonda_externment_kcoca_modal"
												@click="getGoondaExternmentKcoca(rowdy.rowdy_id, 'KCOCA Act')">
												{{ rowdy.kcoca_act_count }}
											</a>
										</td>
										<td class="text-center">
											<a href="javascript:void(0)" data-bs-toggle="modal"
												data-bs-target="#par_cases_modal" @click="getParCases(rowdy.rowdy_id)">
												{{ rowdy.par_cases_count }}
											</a>
										</td>
										<td style="white-space: nowrap;">
											{{ rowdy.created_by?.name }}
										</td>
										<td class="text-center" v-can="'rowdy.delete'">
											<nav v-if="rowdy.can_edit">
												<a href="javascript:void(0)" class="text-danger"
													@click="deleteRowdy(rowdy.rowdy_id)" title="Delete Rowdy">
													<i class="ri-delete-bin-6-line fs-18 lh-1"></i>
												</a>
											</nav>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
				<div class="card-footer d-flex justify-content-between">
					<div>
						<select class="form-select border-0 border-bottom" v-model="meta.per_page"
							@change="setPerPage()">
							<option value="">Select Per Page</option>
							<option value="10">10</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</select>
					</div>
					<div>
						<Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
							@pagechanged="onPageChange" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- View remarks modal -->
	<div class="modal fade" id="viewRemarks" tabindex="-1" aria-labelledby="viewRemarksModal" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="viewRemarksModal">Remarks</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12">
									<textarea name="" class="form-control" id="" cols="30" rows="2"
										placeholder="Enter user remarks" v-model="user_remarks"></textarea>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12">
									<button v-if="edit_remarks" class="btn btn-sm float-end btn-primary mt-2"
										@click="updateUserRemarks()">Update
										Remarks</button>
									<button v-else class="btn btn-sm float-end btn-primary mt-2"
										@click="addUserRemarks()">Add
										Remarks</button>
								</div>
							</div>
							<div class="table-responsive mt-2">
								<table class="table table-sm table-bordered table-striped">
									<thead>
										<tr class="text-center">
											<th class="text-center">SL NO</th>
											<th>LAST UPDATED</th>
											<th>USER</th>
											<th>REMARKS</th>
											<th>ACTIONS</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="remarks.length" v-for="remark, key in remarks" :key="key" class="">
											<td width="10%" class="text-center">{{ key + 1 }}</td>
											<td class="text-center">{{ moment(remark.updated_at) }}
											</td>
											<td>{{ remark.user.name }}</td>
											<td width="50%">{{ remark.remarks }}</td>
											<td class="text-center" width="15%" style="vertical-align: middle;">
												<nav>
													<button v-if="$store.getters.user.user_id == remark.user_id"
														class="btn btn-sm btn-outline-danger me-2"
														@click="deleteUserRemarks(remark.user_remark_id)"><i
															class="ri-chat-delete-line"></i></button>
													<button v-if="$store.getters.user.user_id == remark.user_id"
														class="btn btn-sm btn-outline-primary"
														@click="editRemarks(remark)"><i
															class="ri-pencil-line"></i></button>
												</nav>
											</td>
										</tr>
										<tr v-else class="">
											<td class="text-center" colspan="5">No records to display!</td>
										</tr>
									</tbody>
								</table>
							</div>

						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						@click="discardRemarks()">Close</button>
					<!-- <button type="button" class="btn btn-primary">Add Remarks</button> -->
				</div>
			</div>
		</div>
	</div>

	<!-- IPC cases modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="ipcModal">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">IPC {{ ipc }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead class="text-center">
								<tr>
									<th>SL NO</th>
									<th>Police Station</th>
									<th>Crime Number</th>
									<th>Section of Law</th>
									<th>FIR Stage / <br> Warrant Issued</th>
									<th>Victim/Deceased</th>
									<th>Accomplices Names</th>
									<th>Case Status</th>
									<th>Court Name</th>
									<th>CC / SC Number</th>
									<th>Next Hearing Date</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="ipc_case, key in ipc_cases" v-if="ipc_cases.length">
									<td>{{ key + 1 }}</td>
									<td v-if="ipc_case.police_station?.police_station == 'Others'">{{
										ipc_case.other_police_station }}</td>
									<td v-else>{{ ipc_case.police_station?.police_station }}</td>
									<td>{{ ipc_case.crime_number }}</td>
									<td>{{ ipc_case.section_of_law }}</td>
									<td>{{ ipc_case.warrant_issued ? 'Yes' : 'No' }}</td>
									<td>{{ ipc_case.victim_deceased }}</td>
									<td>{{ ipc_case.accomplices_names }}</td>
									<td>{{ ipc_case.case_status }}</td>
									<td>{{ ipc_case.court_name }}</td>
									<td>{{ ipc_case.cc_sc_number }}</td>
									<td>{{ ipc_case.next_hearing_date }}</td>
									<td class="text-center"><button class="btn btn-sm btn-primary"
											v-if="ipc_case.can_edit_case"
											@click="editIpcCase(ipc_case.rowdy_id, ipc_case.rowdies_case_id)"><i
												class="ri-pencil-line"></i></button></td>
								</tr>
								<tr v-else>
									<td colspan="13" class="text-center">No records to display!...</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="ipc_modal_close">Close</button>
				</div>
			</div>
		</div>
	</div>

	<!-- Goonda act, Externment act and KCOCA act modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="goonda_externment_kcoca_modal">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title"> {{ goonda_title }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead class="text-center">
								<tr>
									<th>SL NO</th>
									<th>Police Station</th>
									<th>Person Name</th>
									<th>Father Name</th>
									<th>Address</th>
									<th>Activities</th>
									<th>Order No.</th>
									<th>Approved / Rejected</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="goonda, key in goondas" v-if="goondas.length">
									<td class="text-center">{{ key + 1 }}</td>
									<td v-if="goonda.police_station?.police_station == 'Others'">{{
										goonda.other_police_station }}</td>
									<td v-else>{{ goonda.police_station?.police_station }}</td>
									<td style="text-transform: uppercase;">{{ goonda.person_name }}</td>
									<td>{{ goonda.father_name }}</td>
									<td>{{ goonda.address }}</td>
									<td class="text-center">{{ goonda.activities }}</td>
									<td>{{ goonda.order_no }}</td>
									<td class="text-center">{{ goonda.approved_status }}</td>
									<td class="text-center">
										<button class="btn btn-sm btn-primary" v-if="goonda.can_edit_goonda"
											@click="editGoondaCase(goonda.rowdy_id, goonda.goonda_id)"><i
												class="ri-pencil-line"></i></button>
									</td>
								</tr>
								<tr v-else>
									<td colspan="13" class="text-center">No records to display!...</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="goonda_modal_close">Close</button>
				</div>
			</div>
		</div>
	</div>

	<!-- PAR cases modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="par_cases_modal">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title"> {{ goonda_title }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive">
						<table class="table table-sm table-bordered table-striped">
							<thead>
								<tr class="text-center">
									<th rowspan="2" class="text-center">Sl No.</th>
									<th rowspan="2">Police Station</th>
									<th rowspan="2">PAR No.</th>
									<th rowspan="2">Year</th>
									<th rowspan="2">Section<br>Of Law</th>
									<th rowspan="2">PAR Date</th>
									<th colspan="2">Bound Over Date</th>
									<th rowspan="2">Surety Details</th>
									<th rowspan="2">If Violated</th>
									<th rowspan="2">Date</th>
									<th v-can="'par_case.delete'" rowspan="2">Action</th>
								</tr>
								<tr class="text-center">
									<th>From</th>
									<th>To</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="par_case, key in par_cases" v-if="par_cases.length">
									<td class="text-center">{{ key + 1 }}</td>
									<td>{{ par_case.police_station?.police_station }}</td>
									<td>{{ par_case.par_no }}</td>
									<td>{{ par_case.year }}</td>
									<td>{{ par_case.section_of_law }}</td>
									<td>{{ moment(par_case.par_date) }}</td>
									<td>{{ moment(par_case.bound_over_from) }}</td>
									<td>{{ moment(par_case.bound_over_to) }}</td>
									<td>{{ par_case.surety_details }}</td>
									<td>{{ par_case.if_violated }}</td>
									<td>{{ moment(par_case.violated_date) }}</td>
									<td v-can="'par_case.delete'" class="text-center"><button
											v-if="par_case.can_edit_par_case" class="btn btn-sm btn-primary"
											@click="editParCase(par_case.rowdy_id, par_case.par_case_id)"><i
												class="ri-pencil-line" title="Delete PAR case"></i></button>
									</td>
								</tr>
								<tr v-else>
									<td colspan="13" class="text-center">No records to display!...</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="par_cases_modal">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!-- pending processes modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="pendingProcesses">
		<div class="modal-dialog modal-xl">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">IPC {{ ipc }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="small mt-2">
						<div class="table-responsive">
							<table class="table table-sm table-bordered table-striped">
								<thead class="text-center">
									<tr>
										<th class="text-center">Sl No.</th>
										<th>Process Type</th>
										<th>Police Station</th>
										<th>Crime Number</th>
										<th>Section of Law</th>
										<th>Court Name</th>
										<th>CC / SC Number</th>
										<th>Issued Date</th>
										<th>Served/Unserved</th>
										<th>Next Hearing Date</th>
										<th>Edit</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="process, key in pending_processes" v-if="pending_processes.length">
										<td class="text-center">{{ key + 1 }}</td>
										<td>{{ process.process_type }}</td>
										<td>{{ process.police_station?.police_station }}</td>
										<td>{{ process.crime_number }}</td>
										<td>{{ process.section_of_law }}</td>
										<td>{{ process.court_name }}</td>
										<td>{{ process.cc_sc_number }}</td>
										<td>{{ process.issued_date }}</td>
										<td>{{ process.status }}</td>
										<td>{{ process.next_hearing_date }}</td>
										<td>
											<a href="javascript:void(0)"
												@click="editProcess(process.process_id, process.rowdy_id)"><i
													class="ri-edit-line fs-18 lh-1" title="Edit rowdy"></i></a>
										</td>
									</tr>
									<tr v-else>
										<td colspan="11" class="text-center">No records to display!...</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="process_modal_close">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!-- IR Report download modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="ir_report_download">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">IR Report Download</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="small mt-2">
						<div class="table-responsive">
							<table class="table table-sm table-bordered table-striped">
								<thead>
									<tr class="text-center">
										<th>Sl No.</th>
										<th>Remarks</th>
										<th>IR Report File</th>
										<th>Uploaded By</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="ir_reports.length" v-for="ir_report, key in ir_reports">
										<td class="text-center">{{ key + 1 }}</td>
										<td>{{ ir_report.remarks }}</td>
										<td class="small text-center"><button
												@click="downloadIRReportFile(ir_report.ir_file)"
												class="btn btn-primary btn-sm">Download</button></td>
										<td>{{ ir_report.user?.name }} : {{ ir_report.user.role.role }}</td>
									</tr>
									<tr v-else>
										<td colspan="4" class="text-center">No records to display!...</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="process_modal_close">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!-- IR Category Document download modal -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="open_closed_order_download">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Opened / Closed Order Copy Download</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="small mt-2">
						<div class="table-responsive">
							<table class="table table-sm table-bordered table-striped">
								<thead>
									<tr class="text-center">
										<th>Sl No.</th>
										<th>Police Station</th>
										<th>Opened Order No.</th>
										<th>Closed Order No.</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="rowdy_categories.length" v-for="category, key in rowdy_categories">
										<td class="text-center">{{ key + 1 }}</td>
										<td>{{ category.police_station?.police_station }}</td>
										<td><span v-if="category.opened_order_no"
												class="d-flex justify-content-between align-items-center">
												{{ category.opened_order_no }}
												<button class="btn btn-sm btn-outline-success"
													@click="downloadOpenedOrderDocument(category.opened_order_file)"><i
														class="ri-download-line"></i></button>
											</span>
										</td>
										<td>
											<span v-if="category.closed_order_no"
												class="d-flex justify-content-between align-items-center">
												{{ category.closed_order_no }}
												<button class="btn btn-sm btn-outline-success"
													@click="downloadClosedOrderDocument(category.closed_order_file)"><i
														class="ri-download-line"></i></button>
											</span>
										</td>
									</tr>
									<tr v-else>
										<td colspan="4" class="text-center">No records to display!...</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
						ref="process_modal_close">Close</button>
				</div>
			</div>
		</div>
	</div>
	<!-- Enlarge image -->
	<div class="modal fade bd-example-modal-lg" tabindex="-1" id="enlargeImageModal">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Rowdy Images</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 d-flex justify-content-around">
							<label class="form-label text-primary">Uploaded By: {{ shift.created_by?.name}}</label>
						</div>
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="this.shift.index > 0" class="btn btn-sm btn-outline-primary float-start"
								@click="shiftLeft()"> <i class="ri-arrow-left-line"></i> </button>
						</div>
						<div class="col-8">
							<img class="modal-img" :src="shift.image" alt="" />
							<div class="text-overlay">{{ moment(shift.photo_taken_date) }}</div>
						</div>
						<div class="col-2" style="display: flex; align-items: center;">
							<button v-if="shift.index <= rowdy_images.length - 2"
								class="btn btn-sm btn-outline-primary float-end" @click="shiftRight()"> <i
									class="ri-arrow-right-line"></i> </button>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import Highlighter from 'vue-highlight-words';
import MultiSelect from 'primevue/multiselect';
import moment from "moment";
import axios from "axios";
export default {
	components: {
		Pagination, Highlighter, MultiSelect
	},
	name: "RowdiesCreate",
	data() {
		return {
			rowdy_list: [],
			rowdies: [],
			rowdy_id: '',
			restore_page: false,
			meta: {
				rowdy_status: [],
				case_types: [],
				search: '',
				order_by: "asc",
				keyword: "rowdy_id",
				per_page: 10,
				totalRows: 0,
				page: 1,
				lastPage: 1,
				from: 1,
				maxPage: 1,
				division_id: '',
				sub_division_id: '',
				police_station_ids: [],
				with_remarks: false,
				with_ir_reports: false,
				opened_order_only: false,
				closed_order_only: false,
				case_count: {
					from: '',
					to: ''
				}
			},
			remarks: [],
			user_remarks: '',
			edit_remarks: false,
			user_remark_id: '',
			scroll_top: 0,
			police_stations: [],
			show: false,
			ipc_cases: [],
			search_police_station: '',
			rowdy_image: '',
			rowdy_images: [],
			shift: {
				index: 0,
				image: '',
				photo_taken_date: ''
			},
			search_police_station: '',
			selected_police_stations: [],
			case_types: ['ATTEMPT_TO_MURDER', 'MURDER', 'CHEATING', 'Others'],
			pending_processes: [],
			rowdy_status: ['Active', 'Inactive', 'Closed', 'Transfer', 'Murdered', 'Died', 'Suicide'],
			goondas: [],
			par_cases: [],
			ir_reports: [],
			rowdy_sheets: 0,
			rowdy_categories: [],			
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.getDivisions()
			vm.getPoliceStations();
			if (sessionStorage.getItem('paths')) {
				sessionStorage.removeItem('paths');
			}
			if (from.name == 'ViewRowdy' | from.name == 'UpdateCases') {
				vm.restore_page = true;
			}
		});
	},

	mounted() {
		if (sessionStorage.getItem('rowdy_meta') && this.restore_page) {
			this.meta = JSON.parse(sessionStorage.getItem('rowdy_meta'));
			sessionStorage.removeItem('rowdy_meta');
		}
		if (sessionStorage.getItem('rowdy_list') && this.restore_page) {
			this.rowdy_list = JSON.parse(sessionStorage.getItem('rowdy_list'));
			sessionStorage.removeItem('rowdy_list');
		}
		if (sessionStorage.getItem('scroll_top') && this.restore_page) {
			this.scroll_top = sessionStorage.getItem('scroll_top');
			sessionStorage.removeItem('scroll_top');
		}
		this.getRowdyList();
	},

	unmounted() {
		sessionStorage.setItem('rowdy_meta', JSON.stringify(this.meta));
		sessionStorage.setItem('rowdy_list', JSON.stringify(this.rowdy_list));
		sessionStorage.setItem('scroll_top', this.scroll_top);
	},

	computed: {
		keywords() {
			return this.meta.search.split(' ')
		}
	},
	methods: {
		getRowdyList() {
			let vm = this;
			let uri = { uri: "paginateRowdies", data: vm.meta };
			vm.$store.dispatch("post", uri)
				.then(function (response) {
					if (!vm.restore_page) {
						vm.rowdy_list = response.data.data;
						vm.rowdy_sheets = response.data.rowdy_sheets;
					}
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
					if (vm.restore_page) {
						vm.$refs.rowdy_scroll.scrollTop = vm.scroll_top;
						vm.restore_page = false;
					}
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
		},
		editRowdy(rowdy_id) {
			this.$router.push("/rowdies/edit/" + rowdy_id);
		},

		addRowdy() {
			this.$router.push('/create_rowdy');
		},

		deleteRowdy(rowdy_id) {
			if (confirm("Are you sure you want to delete")) {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = { uri: 'deleteRowdy', data: { 'rowdy_id': rowdy_id } };
				vm.$store.dispatch("post", uri)
					.then(function (response) {
						loader.hide();
						vm.$store.dispatch("success", response.data.message);
						vm.getRowdyList();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
			}
		},

		viewRowdyGang(rowdy_id) {
			this.$router.push('cases/rowdy/' + rowdy_id);
		},

		onPageChange(page) {
			this.meta.page = page;
			this.getRowdyList();
		},

		sort(field) {
			this.meta.keyword = field;
			this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
			this.getRowdyList();
		},
		search() {
			this.rowdy_list = [];
			this.meta.page = 1;
			this.getRowdyList();
		},

		policeStationCategories(categories) {
			if (!Array.isArray(categories) || categories.length === 0) {
				return '';
			}
			let station_category = [];
			if (categories.length) {
				categories.forEach(ele => {
					// Determine the background color based on rowdy_status
					let bgColor = "";
					if (ele.rowdy_status === "Active") {
						bgColor = "green";
					} else if (ele.rowdy_status === "Inactive") {
						bgColor = "orange";
					} else if (ele.rowdy_status === "Closed") {
						bgColor = "red";
					} else {
						bgColor = "gray";
					}

					// Create an HTML string with styled content
					let str = `
						<span style="background-color: ${bgColor}; padding: 1px; border-radius: 3px; color:#fff">
							${ele.category} - ${ele.police_station.police_station} - ${ele.rowdy_status}
						</span>`;
					station_category.push(str);
				});
			}
			station_category.sort();
			return station_category.join(",<br style='margin-bottom:10px'>");
		},

		policeDivisionCategories(categories) {
			let division_category = [];
			if (categories.length) {
				categories.forEach(ele => {
					let str = ele.category + ' - ' + ele.police_station.division.division;
					division_category.push(str);
				});
			}
			division_category.sort();
			return division_category.join(",<br>");
		},

		policeSubDivisionCategories(categories) {
			let sub_division_category = [];
			if (categories.length) {
				categories.forEach(ele => {
					let str = ele.category + ' - ' + ele.police_station.sub_division.sub_division;
					sub_division_category.push(str);
				});
			}
			sub_division_category.sort();
			return sub_division_category.join(",<br>");
		},

		onScroll(e) {
			const scroll = e.target.offsetHeight + e.target.scrollTop;
			const height = e.target.scrollHeight - 1;
			this.scroll_top = e.target.scrollTop;
			// if (scroll >= height) {
			//     if (this.meta.page < this.meta.lastPage) {
			//         this.meta.page++;
			//         this.getRowdyList();
			//     }
			//     e.target.scrollTop = e.target.scrollTop - 10;
			// }
		},

		downloadRowdies() {
			let police_station_ids = JSON.stringify(this.meta.police_station_ids);
			// window.open(this.$store.getters.downloadUrl + "exportRowdies?police_station_ids=" + police_station_ids + "&from=" + this.meta.case_count.from + "&to=" + this.meta.case_count.to + "&rowdy_status=" + this.meta.rowdy_status + "&case_types=" + this.meta.case_types + '&search=' + this.meta.search);
			let loader = this.$loading.show();
			const downloadExcelWithAuth = async () => {
				try {
					// axios.defaults.withCredentials = true;
					const response = await axios.post(this.$store.getters.downloadUrl + 'api/exportRowdies',
						{
							police_station_ids: police_station_ids,
							from: this.meta.case_count.from,
							to: this.meta.case_count.to,
							rowdy_status: this.meta.rowdy_status,
							case_types: this.meta.case_types,
							search: this.meta.search
						},
						{
							responseType: 'blob',
							headers: {
								Authorization: 'Bearer ' + this.$store.getters.token
							}
						}
					);
					const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = 'export_rowdies.xlsx';
					link.click();
					URL.revokeObjectURL(link.href);
					loader.hide();
				} catch (error) {
					loader.hide();
					console.error('Error downloading the excel:', error);
					alert('There was an issue downloading the excel.');
				}
			};
			downloadExcelWithAuth();
		},

		downloadRowdiesCases() {
			let police_station_ids = JSON.stringify(this.meta.police_station_ids);
			let loader = this.$loading.show();
			const downloadExcelWithAuth = async () => {
				try {
					const response = await axios.post(this.$store.getters.downloadUrl + 'api/exportRowdiesCase',
						{
							//divisions
							division_id: this.meta.division_id,
							sub_division_id: this.meta.sub_division_id,
							police_station_ids: police_station_ids,
							from: this.meta.case_count.from,
							to: this.meta.case_count.to,
							rowdy_status: this.meta.rowdy_status,
							case_types: this.meta.case_types,
							search: this.meta.search
						},
						{
							responseType: 'blob',
							headers: {
								Authorization: 'Bearer ' + this.$store.getters.token
							}
						}
					);
					const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = 'export_rowdies.xlsx';
					link.click();
					URL.revokeObjectURL(link.href);
					loader.hide();
				} catch (error) {
					loader.hide();
					console.error('Error downloading the excel:', error);
					alert('There was an issue downloading the excel.');
				}
			};
			downloadExcelWithAuth();
		},

		downloadRowdiesPdf() {
			let police_station_ids = JSON.stringify(this.meta.police_station_ids);
			// window.open(this.$store.getters.downloadUrl + "exportRowdiesPdf?police_station_ids=" + police_station_ids + "&from=" + this.meta.case_count.from + "&to=" + this.meta.case_count.to + "&rowdy_status=" + this.meta.rowdy_status + "&case_types=" + this.meta.case_types + '&search=' + this.meta.search);
			let loader = this.$loading.show();
			const downloadPDFWithAuth = async () => {
				try {
					// axios.defaults.withCredentials = true;
					const response = await axios.post(this.$store.getters.downloadUrl + 'api/exportRowdiesPdf',
						{
							police_station_ids: police_station_ids,
							from: this.meta.case_count.from,
							to: this.meta.case_count.to,
							rowdy_status: this.meta.rowdy_status,
							case_types: this.meta.case_types,
							search: this.meta.search
						},
						{
							responseType: 'blob',
							headers: {
								Authorization: 'Bearer ' + this.$store.getters.token
							}
						}
					);
					loader.hide();
					const blob = new Blob([response.data], { type: 'application/pdf' });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = 'export_rowdies.pdf';
					link.click();
					URL.revokeObjectURL(link.href);
				} catch (error) {
					loader.hide();
					console.error('Error downloading the PDF:', error);
					alert('There was an issue downloading the PDF.');
				}
			};
			downloadPDFWithAuth();
		},

		getUserRemarks(rowdy_id) {
			this.rowdy_id = rowdy_id;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getUserRemarks', data: { rowdy_id: this.rowdy_id } })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		addUserRemarks() {
			if (this.user_remarks == '') {
				alert('Remarks field cannot be empty!');
				return;
			}
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'addUserRemarks', data: { rowdy_id: this.rowdy_id, remarks: this.user_remarks } })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
					this.getRowdyList();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		deleteUserRemarks(user_remark_id) {
			if (!confirm('This remark will be deleted permanantly!')) {
				return;
			}
			let vm = this;
			let loader = this.$loading.show();
			let data = { rowdy_id: this.rowdy_id, user_remark_id: user_remark_id };
			this.$store.dispatch('post', { uri: 'deleteUserRemarks', data: data })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
					this.getRowdyList();
				})
				.catch(error => {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		editRemarks(remark) {
			this.edit_remarks = true;
			this.user_remarks = remark.remarks;
			this.user_remark_id = remark.user_remark_id;
		},

		updateUserRemarks() {
			this.edit_remarks = false;
			let vm = this;
			let data = { rowdy_id: this.rowdy_id, remarks: this.user_remarks, user_remark_id: this.user_remark_id }
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'updateUserRemarks', data: data })
				.then(response => {
					loader.hide();
					this.remarks = response.data.data;
					this.user_remarks = '';
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		discardRemarks() {
			this.user_remarks = '';
		},

		getDivisions() {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getDivisions' })
				.then(response => {
					loader.hide();
					this.divisions = response.data.data;
					this.getRowdyList();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getSubDivisions() {
			this.meta.sub_division_id = '';
			this.meta.police_station_ids = [];
			this.police_station_id = '';
			this.restore_page = false;
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getSubDivisions', data: { division_id: this.meta.division_id } })
				.then(response => {
					loader.hide();
					this.sub_divisions = response.data.data;
					this.getRowdyList();
					this.getPoliceStationByDivisions()
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getPoliceStationByDivisions() {
			this.meta.police_station_ids = [];
			let vm = this;
			let data = {
				division_id: this.meta.division_id,
				sub_division_id: this.meta.sub_division_id
			}
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getPoliceStationByDivisions', data: data })
				.then(response => {
					loader.hide();
					this.police_stations = response.data.data;
					this.getRowdyList();
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getPoliceStations() {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getPoliceStations' })
				.then(response => {
					loader.hide();
					this.police_stations = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},
		showDropdown() {
			// this.show = !this.show;
			this.show = true;
		},

		hideDropdown() {
			this.show = false;
		},

		onPoliceStationSelected() {
			this.rowdy_list = [];
			this.restore_page = false;
			this.getRowdyList();
		},

		clearSelection() {
			this.meta.police_station_ids = [];
			this.meta.rowdy_status = [];
			this.selected_police_stations = [];
			this.meta.case_types = [];
			this.meta.search = '';
			this.meta.page = 1;
			this.meta.case_count.from = '';
			this.meta.case_count.to = '';
			this.meta.division_id = '';
			this.meta.sub_division_id = '';
			this.getRowdyList();
			this.getPoliceStations();
		},

		getIpcCases(rowdy_id, case_type) {
			let vm = this;
			this.ipc = case_type;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getIpcCases', data: { rowdy_id: rowdy_id, case_type: case_type } })
				.then(response => {
					loader.hide();
					this.ipc_cases = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getGoondaExternmentKcoca(rowdy_id, case_type) {
			let vm = this;
			this.goonda_title = case_type;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getGoondaExternmentKcoca', data: { rowdy_id: rowdy_id, case_type: case_type } })
				.then(response => {
					loader.hide();
					this.goondas = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		getParCases(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show()
			this.$store.dispatch('post', { uri: 'getParCases', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide()
					this.par_cases = response.data.data
				})
				.catch(error => {
					loader.hide()
					vm.errors = error.response.errors
					vm.$store.dispatch('error', error.response.data.message)
				})
		},

		editParCase(rowdy_id, par_case_id) {
			this.$refs.par_cases_modal.click()
			this.$router.push('/rowdy_par_update/' + rowdy_id + '/' + par_case_id)
		},

		editIpcCase(rowdy_id, rowdies_case_id) {
			this.$refs.ipc_modal_close.click();
			this.$router.push('/rowdy_case_update/' + rowdy_id + '/' + rowdies_case_id);
		},

		editGoondaCase(rowdy_id, goonda_id) {
			this.$refs.goonda_modal_close.click()
			this.$router.push('/rowdy_goonda_update/' + rowdy_id + '/' + goonda_id)
		},

		filterPoliceStation() {
			this.police_stations.forEach(ele => { ele.v_flag = true; });
			if (this.search_police_station == '') {
				return;
			}
			this.police_stations.forEach(ele => {
				if (!ele.police_station.toLowerCase().includes(this.search_police_station.toLowerCase())) {
					ele.v_flag = false;
				};
			})
		},

		setRowdyImage(image) {
			this.rowdy_image = image;
		},

		getRowdyImages(rowdy_id) {
			this.shift = {}
			let vm = this;
			let loader = vm.$loading.show();
			this.$store.dispatch('post', { uri: 'getRowdyImages', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.rowdy_images = response.data.data;
					if (this.rowdy_images.length) {
						this.shift.index = 0;
						this.shift.image = this.rowdy_images[0].image;
						this.shift.photo_taken_date = this.rowdy_images[0].photo_taken_date;
						this.shift.created_by = this.rowdy_images[0].created_by;
					}
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		shiftLeft() {
			if (this.rowdy_images.length) {
				this.shift.index--;
				this.shift.image = this.rowdy_images[this.shift.index].image;
				this.shift.photo_taken_date = this.rowdy_images[this.shift.index].photo_taken_date;
				this.shift.created_by = this.rowdy_images[this.shift.index].created_by;
			}
		},

		shiftRight() {
			if (this.rowdy_images.length) {
				this.shift.index++;
				this.shift.image = this.rowdy_images[this.shift.index].image;
				this.shift.photo_taken_date = this.rowdy_images[this.shift.index].photo_taken_date;
				this.shift.created_by = this.rowdy_images[this.shift.index].created_by;
			}
		},


		PoliceStaionFilter(selected_police_stations) {
			let vm = this;
			vm.meta.police_station_ids = []
			selected_police_stations.map(function (ele) {
				vm.meta.police_station_ids.push(ele.police_station_id)
			})
			// vm.rowdy_list = [];
			vm.getRowdyList();
		},

		filterByCase() {
			this.rowdy_list = [];
			this.meta.page = 1;
			this.getRowdyList();
		},

		isNumber(e) {
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'Enter'];
			const keyPressed = e.key;
			if (!keysAllowed.includes(keyPressed)) {
				e.preventDefault()
			}
		},

		getPendingProcesses(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getPendingProcesses', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.pending_processes = response.data.data;
				})
				.catch(error => {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		editProcess(process_id, rowdy_id) {
			this.$refs.process_modal_close.click();
			this.$router.push('/rowdy_process_update/' + rowdy_id + '/' + process_id);
		},

		setPerPage() {
			this.meta.page = 1;
			this.getRowdyList();
		},

		moment(date) {
			if (date) {
				return moment(date).format('DD-MM-YYYY');
			}
		},

		getIRReports(rowdy_id) {
			let vm = this;
			let loader = this.$loading.show();
			this.$store.dispatch('post', { uri: 'getIRReports', data: { rowdy_id: rowdy_id } })
				.then(response => {
					loader.hide();
					this.ir_reports = response.data.data;
				})
				.catch(error => {
					loader.hide()
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error', error.response.data.message);
				})
		},

		downloadIRReportFile(filename) {
			let loader = this.$loading.show();
			const downloadPDFWithAuth = async () => {
				try {
					// axios.defaults.withCredentials = true;
					const response = await axios.post(this.$store.getters.downloadUrl + 'api/downloadIRReportFile',
						{
							filename: filename
						},
						{
							responseType: 'blob',
							headers: {
								Authorization: 'Bearer ' + this.$store.getters.token
							}
						}
					);
					loader.hide();
					let mimeType;
					let fileType = filename.split('.').pop().toLowerCase();
					switch (fileType) {
						case 'pdf':
							mimeType = 'application/pdf';
							break;
						case 'doc':
							mimeType = 'application/msword';
							break;
						case 'docx':
							mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
							break;
						default:
							mimeType = 'application/octet-stream';
							break;
					}
					const blob = new Blob([response.data], { type: mimeType });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = `ir_report_${Date.now()}.${fileType}`;
					link.click();
					URL.revokeObjectURL(link.href);
				} catch (error) {
					loader.hide();
					console.error('Error downloading the file:', error);
					alert('There was an issue downloading the file.');
				}
			};
			downloadPDFWithAuth();
		},

		setOpenedClosedModal(rowdy_categories) {
			this.rowdy_categories = rowdy_categories;
		},

		downloadOpenedOrderDocument(filename) {
			let loader = this.$loading.show();
			const downloadPDFWithAuth = async () => {
				try {
					// axios.defaults.withCredentials = true;
					const response = await axios.post(this.$store.getters.downloadUrl + 'api/downloadOpenedOrderDocument',
						{
							filename: filename
						},
						{
							responseType: 'blob',
							headers: {
								Authorization: 'Bearer ' + this.$store.getters.token
							}
						}
					);
					loader.hide();
					let mimeType;
					let fileType = filename.split('.').pop().toLowerCase();
					switch (fileType) {
						case 'pdf':
							mimeType = 'application/pdf';
							break;
						case 'doc':
							mimeType = 'application/msword';
							break;
						case 'docx':
							mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
							break;
						default:
							mimeType = 'application/octet-stream';
							break;
					}
					const blob = new Blob([response.data], { type: mimeType });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = `Opened_Order_${Date.now()}.${fileType}`;
					link.click();
					URL.revokeObjectURL(link.href);
				} catch (error) {
					loader.hide();
					console.error('Error downloading the file:', error);
					alert('There was an issue downloading the file.');
				}
			};
			downloadPDFWithAuth();
		},

		downloadClosedOrderDocument(filename) {
			let loader = this.$loading.show();
			const downloadPDFWithAuth = async () => {
				try {
					// axios.defaults.withCredentials = true;
					const response = await axios.post(this.$store.getters.downloadUrl + 'api/downloadClosedOrderDocument',
						{
							filename: filename
						},
						{
							responseType: 'blob',
							headers: {
								Authorization: 'Bearer ' + this.$store.getters.token
							}
						}
					);
					loader.hide();
					let mimeType;
					let fileType = filename.split('.').pop().toLowerCase();
					switch (fileType) {
						case 'pdf':
							mimeType = 'application/pdf';
							break;
						case 'doc':
							mimeType = 'application/msword';
							break;
						case 'docx':
							mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
							break;
						default:
							mimeType = 'application/octet-stream';
							break;
					}
					const blob = new Blob([response.data], { type: mimeType });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = `Closed_Order_${Date.now()}.${fileType}`;
					link.click();
					URL.revokeObjectURL(link.href);
				} catch (error) {
					loader.hide();
					console.error('Error downloading the file:', error);
					alert('There was an issue downloading the file.');
				}
			};
			downloadPDFWithAuth();
		},
	}
}
</script>
<style scoped>
.card-one {
	border-width: 0px;
}

@media only screen and (max-width: 767px) {
	.gap-2 {
		margin-bottom: 0.5rem;
	}
}

.dropdown {
	position: relative;
	cursor: pointer;
}

.multiselect {
	position: relative;
}

.multiselect ul {
	border: 1px solid #ddd;
	border-top: 0;
	border-radius: 0 0 3px 3px;
	left: 0px;
	padding: 8px 8px;
	top: -0.1rem;
	width: 100%;
	list-style: none;
	max-height: 150px;
	overflow: auto;
	background: white;
}

.overselect {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.multiselect {
	position: relative;
}

.multiselect ul {
	border: 1px solid #ddd;
	border-top: 0;
	border-radius: 0 0 3px 3px;
	left: 0px;
	padding: 8px 8px;
	top: -0.1rem;
	width: 100%;
	list-style: none;
	max-height: 150px;
	overflow: auto;
	background: white;
}

thead {
	/* position: relative !important; */
	top: -2px !important;
	z-index: 5 !important;
	background-color: #f3f5f9
}

.modal-body .col-8 {
	position: relative;
}

.modal-body .modal-img {
	width: 100%;
	height: auto;
}

.text-overlay {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	color: white;
	font-size: 18px;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 5px;
	border-radius: 5px;
}
</style>
